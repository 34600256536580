import * as http from "shared/http";
import { Paginated, MaterialType } from "shared/types";
import useSWR from "swr";

export function getMaterialsGoal(url: string) {
    return http.request<Paginated<MaterialType>>({
        method: "get",
        url: url,
    });
}

export default function useMaterialsGoal(goalId) {
    const { data, error } = useSWR(
        `/api/v1/user/trajectory_active/${goalId}/content`,
        getMaterialsGoal
    );

    return {
        materialsList: data,
        isLoading: !error && !data,
        isError: error,
    };
}



export const useMaterialsGoal2 = async (
    goalId: string
) => {
    try {
        const res = await http.request<Paginated<MaterialType>>({
            method: "get",
            url: `/api/v1/user/trajectory_active/${goalId}/content`,
        });

        const data = res.results as MaterialType[];
        return data;
    } catch (err) {
        console.log(err);
    }
};
