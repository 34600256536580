import { useState, useEffect } from "react";
import { SkillItem } from "../../components/SkillItem/SkillItem";
import { SkillItemSmall } from "../../components/SkillItem/SkillItemSmall";
import { TitleLink } from "../../components/TitleLink/TitleLink";
import useTrajectory_actives from "shared/api/useTrajectory_actives";
import { Loader } from "components/Loader/Loader";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";

const ProfileActivesModel =
    CreatePaginatedModel<types.TrajectoryActiveType>("ProfileActives");

function Skills() {
    const { data, isLoading } = useTrajectory_actives();
    const [width, setWidth] = useState(window.innerWidth);

    const ModelApi = CreateModelApi(
        ProfileActivesModel,
        `/api/v1/user/trajectory_actives`,
        "",
        10
    );

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    // TODO добавить крутилку
    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-screen w-full flex-col items-center overflow-y-scroll">
            <TitleLink title="Ваши текущие навыки" />
            {data?.results?.length > 0 ? (
                <ul className="flex h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px]">
                    {data.results.map((profile, key) => (
                        <li
                            className="mt-[20px] w-full max-w-[1415px] px-[16px] max-[834px]:mt-[10px]"
                            key={key}
                            children={<SkillItem active={profile} />}
                        />
                    ))}
                    {data.next && (
                        <button
                            className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                            onClick={() => ModelApi.appendNextPage(data)}
                        >
                            Загрузить еще
                        </button>
                    )}
                </ul>
            ) : (
                <p className="flex justify-center text-center text-[25px] font-bold">
                    Ваши учебные профили формируются
                </p>
            )}
        </section>
    );
}

export default Skills;
