import * as http from "shared/http";
import { Paginated, TrajectoryActiveType } from "shared/types";
import useSWR from "swr";

export function getTrajectory_actives() {
    return http.request<Paginated<TrajectoryActiveType>>({
        method: "get",
        url: "/api/v1/user/trajectory_actives",
    });
}

export default function useTrajectory_actives() {
    const { data, error } = useSWR(
        "getTrajectory_actives",
        getTrajectory_actives
    );

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}
