import React from "react";
import RegistrationForm from "shared/ui/forms/RegistrationForm";

import { ReactComponent as FullLogo } from "shared/image/icon/FullLogo.svg";
import greeting from "shared/image/greeting.png";

function Authorization() {
    return (
        <section className="grid h-screen grid-cols-2">
            <div className="flex bg-[#F9FDFF]">
                <div className="mx-auto my-auto flex w-[300px] flex-col items-center md:w-[350px] lg:w-[400px] xl:w-[550px]">
                    <FullLogo className="w-[300px] md:w-[350px] lg:w-[400px] xl:w-[445px]" />
                    <img src={greeting} alt="greeting" />
                    <p className="mt-6 text-lg font-light">
                        Ученье свет, а без{" "}
                        <b className="font-bold text-blue">траекто</b> тьма
                    </p>
                </div>
            </div>

            <RegistrationForm />
        </section>
    );
}

export default Authorization;
