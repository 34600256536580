import { useNavigate } from "react-router-dom";
import BrokenLock from "shared/ui/icon/BrokenLock.svg";
import { truncateText } from "../../utils/truncateText";
import { TrajectoryActiveType } from "shared/types";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import Img2 from "../../shared/ui/icon/Lock.svg";

interface Props {
    active: TrajectoryActiveType;
}

export const GoalItemSmallBlocked: React.FC<Props> = ({ active }) => {
    const navigate = useNavigate();

    const handleClickLink = async () => {
        navigate(`/timeline/${active.id}`, { state: { active } });
    };

    return (
        <div
            className="group relative flex h-[410px] w-full flex-col rounded-xl bg-white text-[16px] font-bold text-textblack shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
            onClick={handleClickLink}
        >
            <div className="mb-[20px] flex justify-between px-2.5 pt-2.5">
                <div className="flex flex-col gap-[5px]">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                        Цель
                    </p>
                    <p className="relative text-[14px] font-semibold leading-[16.8px] text-textblack">
                        {truncateText(active.active.name, 1.4)}
                    </p>
                </div>
            </div>
            <div className="relative flex h-full w-full flex-col rounded-l-md px-2.5">
                <div className="mb-[21px] flex w-full justify-start text-end">
                    <img
                        className="h-[22px] w-[22px]"
                        src={BrokenLock}
                        alt="statistic"
                    />
                    <p className="ml-[5px] mt-[5px] text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                        Заблокировано
                    </p>
                </div>
                <div className="mb-[20px] flex w-full flex-col">
                    <p className="mb-[35px] text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Прогресс достижения цели
                    </p>
                    <ProgressBar
                        percent={
                            Math.round(
                                (Number(active.completed_threats_count)/Number(active.threats_count)) || 0)
                        }
                        background="#F37D73"
                        color="#F37D73"
                        height="6px"
                        fontSize="20px"
                        lineHeight="24px"
                        top="-30px"
                        right="-25px"
                    />
                </div>
                <div className="relative flex w-full flex-col justify-between">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Освоенных навыков в рамках цели
                    </p>
                    <p className="text-[32px] text-[#F37D7366]">
                        {active?.completed_threats_count}
                    </p>
                    <p className="mt-[25px] cursor-pointer text-[12px] font-medium leading-[12px] text-[#264354B2]">
                        {`${
                            Math.round(
                                (Number(active?.completed_threats_count) *
                                    100) /
                                    Number(active?.threats_count)
                            ) === 0
                                ? "Начать обучение"
                                : "Продолжить обучение"
                        }`}
                    </p>
                </div>
                <img
                    className="absolute bottom-0 right-0 z-0 h-[136.66px] w-[112.06px] transform opacity-[0.7]"
                    src={Img2}
                    alt="Замок"
                />
            </div>
        </div>
    );
};
