import React from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { ButtonStart } from "../ButtonStart/ButtonStart";
import Statistic from "shared/ui/icon/Statistic.svg";
import Img from "../../shared/ui/icon/skill.svg";
import { truncateText } from "../../utils/truncateText";
import { ActiveThreatType } from "shared/types";

interface Props {
    active: ActiveThreatType;
}

export const SkillItemSmall: React.FC<Props> = ({ active }) => {
    const navigate = useNavigate();

    const handleClickLink = async () => {
        navigate(`/skill/${active.id}`, { state: { active } });
    };

    return (
        <div className="group relative flex h-[410px] w-full flex-col rounded-xl bg-white text-[16px] font-bold text-textblack shadow-[0_0_10px_0_rgba(38,67,84,0.1)]">
            <div className="mb-[20px] flex justify-between px-2.5 pt-2.5">
                <div className="flex flex-col gap-[5px]">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                        Навык
                    </p>
                    <p className="text-[14px] font-semibold leading-[16.8px] text-textblack">
                        {truncateText(active.threat?.name, 1)}
                    </p>
                </div>
            </div>
            <div className="relative flex h-full w-full flex-col rounded-l-md px-2.5">
                <div className="mb-[21px] flex w-full justify-start text-end">
                    <div className="mb-[24px] flex min-w-[200px] justify-start text-end max-[1010px]:mb-[12px]">
                        <img
                            className="h-[22px] w-[22px]"
                            src={Statistic}
                            alt="statistic"
                        />
                        <p className="ml-[5px] mt-[5px] text-sm font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Твой прогресс по навыку
                        </p>
                    </div>
                </div>
                <div className="mb-[20px] flex w-full flex-col">
                    <p className="mb-[35px] text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Прогресс достижения навыка
                    </p>
                    <ProgressBar
                        percent={
                            Math.round(
                                (Number(active.completed_content_count) * 100) /
                                    Number(active.content_count)
                            ) || 0
                        }
                        background="#F37D73"
                        color="#F37D73"
                        height="6px"
                        fontSize="20px"
                        lineHeight="24px"
                        top="-30px"
                        right="-25px"
                    />
                </div>
                <div className="relative flex w-full flex-col justify-between">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Освоенных учебных материалов в рамках навыка
                    </p>
                    <p className="text-[32px] text-[#F37D7366]">
                        {active?.completed_content_count || 0}
                    </p>
                    <ButtonStart
                        text={`${
                            Math.round(
                                (Number(active?.completed_content_count) *
                                    100) /
                                    Number(active?.content_count)
                            ) === 0
                                ? "Начать обучение"
                                : "Продолжить обучение"
                        }`}
                        handleClickLink={handleClickLink}
                    />
                </div>
                <img
                    className="absolute bottom-0 right-0 h-[136.66px] w-[112.06px] transform opacity-[0.7]"
                    src={Img}
                    alt="Картинка"
                />
            </div>
        </div>
    );
};
