import { truncateText } from "utils/truncateText";
import { TrajectoryActiveType } from "shared/types";
import { ReactComponent as RoundTimline } from "../../shared/ui/icon/RoundTimline.svg";
import { ReactComponent as RoundTimlineNS } from "../../shared/ui/icon/RoundTimlineNS.svg";

interface Props {
    active: TrajectoryActiveType | undefined;
    setOpenThreats: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenMaterials: React.Dispatch<React.SetStateAction<boolean>>;
    activeListLenght: number;
    index: number;
    selectedActiveId: number;
    setSelectedActiveId: (number) => void;
}

export const ActiveCard: React.FC<Props> = ({
    active,
    setOpenThreats,
    setOpenMaterials,
    index,
    activeListLenght,
    selectedActiveId,
    setSelectedActiveId,
}) => {
    const activeStat = (
        (active?.completed_threats_count / active?.threats_count) * 100 || 0
    ).toFixed(0);
    const activePos = index + 1;

    function openActive() {
        setSelectedActiveId(active.id);
        setOpenThreats(true);
    }

    // unused
    function closeActive() {
        setSelectedActiveId(active.id); // wtf
        setOpenThreats(false);
        setOpenMaterials(false);
    }

    return (
        <div
            className="relative flex min-h-[90px] w-[455px] cursor-pointer gap-x-[30px]"
            onClick={openActive}
        >
            <div
                className={`relative ${
                    activeListLenght === activePos
                        ? " "
                        : "after:absolute after:bottom-0 after:start-3.5 after:top-7 after:w-px after:-translate-x-[0.5px] after:bg-[#F3A773] last:after:hidden"
                } `}
            >
                <div className="relative z-10 flex size-7 items-center justify-center">
                    {selectedActiveId === active.id ? (
                        <RoundTimlineNS />
                    ) : (
                        <RoundTimline />
                    )}
                </div>
            </div>

            <div className="grow pb-[60px] pt-0.5 ">
                <div className="flex w-full justify-between">
                    <div className="flex gap-x-[20px] ">
                        <p className="w-[70px] text-start text-[24px] font-bold leading-[24px] text-orange">
                            {activeStat} %
                        </p>
                        <div className="flex flex-col gap-y-[15px]">
                            <div className="flex h-[20px] min-w-[80px] max-w-[80px] items-center justify-center rounded bg-[#369F481A]">
                                <p className="text-[12px] font-normal leading-[14.4px] text-[#369F48]">
                                    {active.non_empty_threats_count | 0} навыков
                                </p>
                            </div>
                            <p className="flex w-[220px] break-words text-[16px] font-medium leading-[19.2px]">
                                {truncateText(active?.active?.name, 5)}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col items-end justify-end gap-y-[15px]">
                        <div className="flex gap-x-[10px] text-[12px] font-medium leading-[14.4px] text-[#ADADAD]">
                            Открыть &#8594;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
