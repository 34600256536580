import { useState } from "react";
import { ReactComponent as Exit } from "shared/ui/icon/Exit.svg";
import { ReactComponent as MenuSelect } from "shared/ui/icon/MenuSelect.svg";
import { ReactComponent as Notifications } from "shared/ui/icon/Notifications.svg";
import { ReactComponent as Calendar } from "shared/ui/icon/Calendar.svg";
import { ReactComponent as MenuSelectWhite } from "shared/ui/icon/MenuSelectWhite.svg";
import { ReactComponent as CalendarSelectWhite } from "shared/ui/icon/CalendarSelectWhite.svg";
import { ReactComponent as NotificationsWhite } from "shared/ui/icon/NotificationsWhite.svg";
import { NavbarMenu } from "../NavbarMenu/NavbarMenu";
import { NavbarNotification } from "components/NavbarNotification/NavbarNotification";
import "./TabBar.css";
import { NavbarCalendar } from "components/NavbarCalendar/NavbarCalendar";

export const TabBar = () => {
    const openCalendarStorage = JSON.parse(sessionStorage.getItem("calendar"));
    const openMenuStorage = JSON.parse(sessionStorage.getItem("menu"));
    const openNotificationsStorage = JSON.parse(
        sessionStorage.getItem("notifications")
    );
    const [openMenu, setOpenMenu] = useState(false); // Стейт открытия меню
    const [openNotifications, setOpenNotifications] = useState(false); // Стейт открытия уведомлений
    const [openCalendar, setOpenCalendar] = useState(false);

    const toggleMenu = () => {
        setOpenMenu((prevState) => !prevState);
        if (!openMenu) {
            sessionStorage.setItem("menu", JSON.stringify(true));
            sessionStorage.setItem("notifications", JSON.stringify(false));
            sessionStorage.setItem("calendar", JSON.stringify(false));
        }
    }; // Условие открытия меню

    const toggleNotifications = () => {
        setOpenNotifications((prevState) => !prevState);
        if (!openNotifications) {
            sessionStorage.setItem("notifications", JSON.stringify(true));
            sessionStorage.setItem("menu", JSON.stringify(false));
            sessionStorage.setItem("calendar", JSON.stringify(false));
        }
    }; // Условие открытия уведомлений

    const toggleCalendar = () => {
        setOpenCalendar((prevState) => !prevState);
        if (!openCalendar) {
            sessionStorage.setItem("menu", JSON.stringify(false));
            sessionStorage.setItem("notifications", JSON.stringify(false));
            sessionStorage.setItem("calendar", JSON.stringify(true));
        }
    }; // Условие открытия уведомлений

    return (
        <>
            <div className="tabBar">
                <div className="tabBar_container">
                    <div
                        className="flex h-[44px] cursor-pointer items-center justify-center"
                        onClick={toggleNotifications}
                    >
                        {openNotificationsStorage ? (
                            <div className="flex h-full items-center justify-center gap-[14px] rounded-[29px] bg-[#264354E5] pl-[18px] pr-[27px] hover:opacity-90">
                                <NotificationsWhite />
                                <p className="text-[12px] font-medium leading-[14.4px] text-[#FFFFFF]">
                                    Уведомления
                                </p>
                            </div> // Закрытые оповещения (иконка)
                        ) : (
                            <div className="flex w-[44px] items-center justify-center hover:opacity-70">
                                <Notifications
                                    data-tooltip-content="Уведомления"
                                    data-tooltip-id="notifications"
                                />
                            </div> // Открытые оповещения (иконка)
                        )}
                        {/* Открытие оповещений */}
                    </div>
                    <div
                        className="flex h-[44px] cursor-pointer items-center justify-center"
                        onClick={toggleMenu}
                    >
                        {openMenuStorage ? (
                            <div className="flex h-full cursor-pointer items-center justify-center gap-[14px] rounded-[29px] bg-[#264354E5] pl-[18px] pr-[27px] hover:opacity-90">
                                <MenuSelectWhite />
                                <p className="text-[12px] font-medium leading-[14.4px] text-[#FFFFFF]">
                                    Меню
                                </p>
                            </div> // Закрытое меню (иконка)
                        ) : (
                            <div className="flex w-[44px] items-center justify-center hover:opacity-70">
                                <MenuSelect
                                    data-tooltip-content="Меню"
                                    data-tooltip-id="menu"
                                />
                            </div> // Открытое меню (иконка)
                        )}
                        {/* Состояние открытия меню (иконка) */}
                    </div>
                    <div
                        className="flex h-[44px] cursor-pointer items-center justify-center"
                        onClick={toggleCalendar}
                    >
                        {openCalendarStorage ? (
                            <div className="flex h-full cursor-pointer items-center justify-center gap-[14px] rounded-[29px] bg-[#264354E5] pl-[18px] pr-[27px] hover:opacity-90">
                                <CalendarSelectWhite />
                                <p className="text-[12px] font-medium leading-[14.4px] text-[#FFFFFF]">
                                    Календарь
                                </p>
                            </div> // Закрыт календарь (иконка)
                        ) : (
                            <div className="flex w-[44px] items-center justify-center hover:opacity-70">
                                <Calendar
                                    data-tooltip-content="Календарь"
                                    data-tooltip-id="сalendar"
                                />
                            </div> // Открыт календарь (иконка)
                        )}
                        {/* Состояние открытия календаря (иконка) */}
                    </div>
                    <div
                        className="flex h-[44px] w-[47px] cursor-pointer items-center justify-center"
                        onClick={() => {
                            localStorage.clear();
                            window.location.assign("/login");
                        }}
                    >
                        <Exit
                            data-tooltip-content="Выход"
                            data-tooltip-id="exit"
                        />
                    </div>
                </div>
            </div>
            <NavbarNotification
                openNotifications={openNotifications}
                toggleNotifications={toggleNotifications}
            />
            {/* Оповещения */}
            <NavbarMenu openMenu={openMenu} toggleMenu={toggleMenu} />
            {/* Меню */}
            <NavbarCalendar
                openCalendar={openCalendar}
                toggleCalendar={toggleCalendar}
            />
            {/* Календарь */}
        </>
    );
};
