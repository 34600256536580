import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSkills from "shared/api/useSkillsLib";
import { SkillItemLib } from "components/SkillItem/SkillItemLib";
import { Loader } from "components/Loader/Loader";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { SkillItemBlocked } from "components/SkillItem/SkillItemBlocked";
import { SkillItemSmall } from "components/SkillItem/SkillItemSmall";

const ActiveThreatTypeModel =
    CreatePaginatedModel<types.ActiveThreatType>("ActiveThreatType");

export const SkillsLib: React.FC = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useSkills();
    const [width, setWidth] = useState(window.innerWidth);

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const ModelApi = CreateModelApi(
        ActiveThreatTypeModel,
        `/api/v1/user/actives_threats`,
        "",
        10
    );

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <main className="relative flex h-screen w-full flex-col overflow-hidden">
            <section className="relative flex h-screen w-full flex-col items-center overflow-hidden">
                <div className="mb-[10px] mt-[30px] flex max-h-[61px] w-full max-w-[1415px] grow items-center justify-between px-[16px]">
                    <div className="flex flex-col">
                        <p className="h-[37px] cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                            <span onClick={handleClickLinkBack}>
                                {"\u2190  "}
                            </span>
                            <span onClick={handleClickLinkMain}>Главная</span>
                        </p>
                        <h2 className="title">Ваши текущие навыки</h2>
                    </div>
                </div>
                {data?.results?.length > 0 ? (
                    <ul className="flex h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px]">
                        {data.results.map((profile, key) => (
                            <>
                                {width > 834 ? (
                                    profile.is_super === true ? (
                                        <li
                                            className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                            key={key}
                                            children={
                                                <SkillItemBlocked
                                                    active={profile}
                                                />
                                            }
                                        />
                                    ) : (
                                        <li
                                            className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                            key={key}
                                            children={
                                                <SkillItemLib
                                                    active={profile}
                                                />
                                            }
                                        />
                                    )
                                ) : (
                                    <li
                                        className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                        key={key}
                                        children={
                                            <SkillItemSmall active={profile} />
                                        }
                                    />
                                )}
                            </>
                        ))}
                        {data.next && (
                            <button
                                className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                                onClick={() => ModelApi.appendNextPage(data)}
                            >
                                Загрузить еще
                            </button>
                        )}
                    </ul>
                ) : (
                    <>
                        <p className="flex justify-center text-center text-[25px] font-bold">
                            Навыки еще не добавлены в ваш профиль
                        </p>
                        <button onClick={() => navigate("/timeline")}>
                            Таймлайн
                        </button>
                    </>
                )}
            </section>
        </main>
    );
};

export default SkillsLib;
