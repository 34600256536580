import React from "react";
import ReactDOM from "react-dom/client";
import Toaster from "shared/ui/Toaster";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./index.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "queryClient";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App />
                <Toaster />
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);
