import * as http from "shared/http";
import toast from "react-hot-toast";

export const editPassword = (
    old_password: string,
    new_password: string,
) => {
    http.request({
        method: "patch",
        url: `/api/v1/profile`,
        data: {
            old_password: old_password,
            new_password: new_password,
        },
    })
    .then((res) => {
        toast.success("Пароль изменен");
    })
};