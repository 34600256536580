import * as http from "shared/http";
import { Paginated, MaterialType } from "shared/types";
import useSWR from "swr";

export function getMaterials() {
    return http.request<Paginated<MaterialType>>({
        method: "get",
        url: "/api/v1/user/content",
    });
}

export default function useMaterialsLib() {
    const { data, error } = useSWR("getMaterials", getMaterials);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}