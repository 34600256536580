import { ReactComponent as Cross } from "shared/ui/icon/CrossIcon.svg";
import React from "react";
import * as types from "shared/types";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { SkillItemLib } from "./SkillItemLib";
import useThreatDeps from "shared/api/useThreatDeps";

interface Props {
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    activeId: string | number;
}

const TrajectoryActivesModel =
    CreatePaginatedModel<types.ActiveThreatType>("TrajectoryActives");

export const SkillDependeces: React.FC<Props> = ({
    setShowPopup,
    activeId,
}) => {
    const isLoading = false;
    const { data } = useThreatDeps(activeId);

    const ModelApi = CreateModelApi(
        TrajectoryActivesModel,
        `/api/v1/user/actives_threats/${activeId}/dependencies`,
        "",
        10
    );

    const handleClickClose = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        setShowPopup(false);
    };

    return (
        <section
            className="absolute z-50 mb-5"
            onClick={(evt) => evt.stopPropagation()}
        >
            <div className="fixed inset-0 bg-[#73C3F3] opacity-30 transition-opacity" />
            <div className="fixed inset-0 z-10 flex  flex-col overflow-y-scroll">
                <div className="relative mx-10 mb-[30px] mt-[90px] flex w-auto grow rounded-xl border bg-white">
                    <div className="flex h-full w-full flex-col  ">
                        <div className="flex h-[100px] w-auto rounded-t-xl bg-[#EFF6FB]">
                            <div className="my-[26px] ml-[25px] flex w-full">
                                <p className="text-[24px] font-bold text-[#264354CC]">
                                    Зависимости навыка
                                </p>
                            </div>
                            <div
                                className="my-[15px] mr-[15px] flex justify-end  "
                                onClick={handleClickClose}
                            >
                                <Cross className="cursor-pointer fill-black" />
                            </div>
                        </div>
                        <div className="min-h-[500px] w-full grow flex-col ">
                            {isLoading === false ? (
                                <div className="relative mx-[25px] mt-[25px] flex grow pb-[20px]">
                                    {data?.results?.length > 0 ? (
                                        <ul className="flex h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px]">
                                            {data.results.map(
                                                (profile, key) => (
                                                    <li
                                                        className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                                        key={key}
                                                        children={
                                                            <SkillItemLib
                                                                active={profile}
                                                            />
                                                        }
                                                    />
                                                )
                                            )}
                                            {data.next && (
                                                <button
                                                    className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                                                    onClick={() =>
                                                        ModelApi.appendNextPage(
                                                            data
                                                        )
                                                    }
                                                >
                                                    Загрузить еще
                                                </button>
                                            )}
                                        </ul>
                                    ) : (
                                        <p className="flex justify-center text-[25px] font-bold">
                                            Цели еще не добавлены в ваш профиль
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <div className="mt-10 flex h-[300px] w-full flex-col items-center">
                                    Загрузка
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
