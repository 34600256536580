import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";
import { CreatePaginatedModel } from "shared/paginatedModelBuilder";
import { Paginated, ActiveThreatType } from "shared/types";

export function getThreatDeps(url: string) {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: url,
    });
}

export default function useThreatDeps(id: number | string) {
    const { data, error } = useSWR(
        `/api/v1/user/actives_threats/${id}/dependencies`,
        getThreatDeps
    );

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export const ActiveThreatTypeModel = CreatePaginatedModel<types.ActiveThreatType>("ActiveThreatType")