import * as http from "shared/http";
import { Paginated, MaterialType } from "shared/types";
import useSWR from "swr";
import { useQuery } from "@tanstack/react-query";

export function getMaterialsSkill(skillId: string) {
    return http.request<Paginated<MaterialType>>({
        method: "get",
        url: `/api/v1/user/active_threat/${skillId}/content`,
    });
}

export default function useMaterialsSkill(skillId) {
    const { data, status, error } = useQuery({
        queryKey: ["materials-list", skillId],
        queryFn: () => getMaterialsSkill(skillId),
    });

    return {
        materialsList: data,
        isLoadingMaterial: status === "pending",
        isError: status === "error",
    };
}
