import { ReactComponent as Achievement } from "shared/image/icon/Achievement.svg";

export const Achievements = ({ achievement }) => {
    return (
        <div className="flex w-[335px] flex-col items-center gap-[25px] px-[5px]">
            <Achievement />
            <p className="text-[30px] font-black leading-[36px] text-[#566DA3]">
                75%
            </p>
            <p className="mb-[18px] text-[14px] font-bold leading-[16.8px] text-[#264354]">
                Название достижения
            </p>
            <div className="blue-shadow mb-[5px] flex w-full flex-col gap-[8px] rounded-[7px] p-[14px]">
                <div className="flex justify-between">
                    <p className="text-[10px] font-medium leading-[12px] text-[#73C3F3]">
                        Статья
                    </p>
                    <div className="flex gap-[10px]">
                        <p className="text-[10px] font-normal leading-[12px] text-[#F37D73]">
                            Длительность
                        </p>
                        <p className="text-[10px] font-normal leading-[12px] text-[#ADADAD]">
                            15 минут
                        </p>
                    </div>
                </div>
                <p className="text-start text-[12px] font-medium leading-[14.4px] text-[#264354]">
                    Название статьи
                </p>
                <p className="text-start text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                    Продолжить изучение &#8594;
                </p>
            </div>
        </div>
    );
};
