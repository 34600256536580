import useSWR from "swr";

import * as http from "shared/http";
import * as types from "shared/types";

export function getEvents(url: string) {
    return http.request<types.Events>({
        method: "get",
        url: url,
    });
}

export default function useEvents() {
    const { data, error } = useSWR(
        "/api/v1/user/logs/list",
        getEvents
    );

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}
