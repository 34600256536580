import { useQuery } from "@tanstack/react-query";
import { getEvents, useStore, useStoreDate } from "./model";

export const Calendar = () => {
    const selectedDay = useStore((s) => s.selectedDay);
    const setSelectedDay = useStore((s) => s.setSelectedDay);
    const monthOffset = useStore((s) => s.monthOffset);
    const addMonthOffset = useStore((s) => s.addMonthOffset);

    const { currentMonth, currentYear } = useStoreDate();
    const { data, status, error } = useQuery({
        queryKey: ["events-list", currentYear, currentMonth],
        queryFn: () => getEvents(currentYear, currentMonth + 1),
    });

    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    function firstDayOfMonth(month: number, year: number) {
        // getDay возвращает день в *извращенном* американском формате
        const SundayToMonday = [6, 0, 1, 2, 3, 4, 5];

        const sundayFirstDay = new Date(year, month, 1).getDay();
        return SundayToMonday[sundayFirstDay];
    }

    function makeDays(month: number, year: number) {
        const result: [number, boolean, number][][] = [];

        const thisMonthDays = new Date(year, month + 1, 0).getDate();
        const prevMonthDays = new Date(year, month, 0).getDate();
        const firstDayThisMonth = firstDayOfMonth(month, year);

        const transformedEvents: { [key: string]: number } = {};
        for (const event of data || []) {
            const eventDate = new Date(event.event_date);
            const dateKey = eventDate.toLocaleDateString();
            transformedEvents[dateKey] = (transformedEvents[dateKey] || 0) + 1;
        }

        for (let i = 0; i < 6; i++) {
            const week: [number, boolean, number][] = [];
            for (let j = 0; j < 7; j++) {
                const index = i * 7 + j;

                if (index < firstDayThisMonth) {
                    const day = prevMonthDays - firstDayThisMonth + index + 1;
                    const eventDate = new Date(year, month - 1, day);
                    const dateKey = eventDate.toLocaleDateString();
                    const events = transformedEvents[dateKey] || 0;

                    week[j] = [day, false, events];
                } else if (index < firstDayThisMonth + thisMonthDays) {
                    const day = index - firstDayThisMonth + 1;
                    const eventDate = new Date(year, month, day);
                    const dateKey = eventDate.toLocaleDateString();
                    const events = transformedEvents[dateKey] || 0;

                    week[j] = [index - firstDayThisMonth + 1, true, events];
                } else {
                    const day = index - firstDayThisMonth - thisMonthDays + 1;
                    const eventDate = new Date(year, month, day);
                    const dateKey = eventDate.toLocaleDateString();
                    const events = transformedEvents[dateKey] || 0;

                    week[j] = [day, false, events];
                }
            }
            result.push(week);
        }
        return result;
    }

    function handleSetDay(day: number, isCurrentMonth: boolean) {
        if (isCurrentMonth === false) {
            if (day < 15) {
                addMonthOffset(1);
            } else {
                addMonthOffset(-1);
            }
        }
        setSelectedDay(day);
    }

    // день для подсветки в календаре
    const today = new Date().getDate();

    function handleNextMonth() {
        if (monthOffset === -1) {
            setSelectedDay(today);
        } else {
            setSelectedDay(1);
        }
        addMonthOffset(1);
    }

    function handlePrevMonth() {
        if (monthOffset === 1) {
            setSelectedDay(today);
        } else {
            setSelectedDay(1);
        }
        addMonthOffset(-1);
    }

    // формируем матрицу дней
    const days = makeDays(currentMonth, currentYear);
    // console.log(days);

    return (
        <div className="">
            <p className="text-tr-m font-semibold text-main-dark">События</p>
            <div className="mt-10 flex items-center justify-between px-5">
                <span className="text-tr-m font-medium text-main-dark">
                    {months[currentMonth]} {currentYear}
                </span>
                <span className="space-x-4">
                    <button
                        aria-label="calendar backward"
                        className="text-main-dark hover:opacity-70"
                        onClick={handlePrevMonth}
                    >
                        ←
                    </button>
                    <button
                        aria-label="calendar forward"
                        className="text-main-dark hover:opacity-70"
                        onClick={handleNextMonth}
                    >
                        →
                    </button>
                </span>
            </div>
            <table className="mt-[14px]">
                <thead>
                    <tr>
                        {["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"].map(
                            (day) => (
                                <th
                                    key={day}
                                    className="h-[53px] w-[68px] font-normal max-[1240px]:w-[50px]"
                                >
                                    {day}
                                </th>
                            )
                        )}
                    </tr>
                </thead>
                <tbody>
                    {days.map((week, weekKey) => (
                        <tr
                            key={weekKey}
                            className="border-b border-monochrome-grey"
                        >
                            {week.map(
                                (
                                    [day, isCurrentMonth, eventsCount],
                                    dayKey
                                ) => (
                                    <td
                                        key={dayKey}
                                        className="relative px-[10px] py-[2.5px] max-[1240px]:px-[5px]"
                                    >
                                        <button
                                            role="link"
                                            tabIndex={0}
                                            data-isToday={
                                                monthOffset === 0 &&
                                                day === today &&
                                                isCurrentMonth
                                            }
                                            data-isSelected={
                                                day === selectedDay &&
                                                isCurrentMonth
                                            }
                                            data-isCurrentMonth={isCurrentMonth}
                                            onClick={() =>
                                                handleSetDay(
                                                    day,
                                                    isCurrentMonth
                                                )
                                            }
                                            className="flex h-[48px] w-[48px] items-center justify-center rounded-full text-tr-m text-monochrome-grey data-[isSelected=true]:bg-main-dark data-[isCurrentMonth=true]:text-main-dark data-[isSelected=true]:!text-white data-[isToday=true]:text-main-red data-[isSelected=true]:outline-none hover:bg-main-dark/10"
                                        >
                                            {day}
                                        </button>
                                        {eventsCount > 0 && (
                                            <span className="absolute right-1 top-1 z-50 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-main-red text-tr-xxs text-white">
                                                {Math.min(eventsCount, 99)}
                                            </span>
                                        )}
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
