import ElementNew from "../Card/Card";
import { useState } from "react";
import LineRed from "shared/ui/icon/LineRed.svg";
import LineBlue from "shared/ui/icon/LineBlue.svg";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import Img from "../../shared/ui/icon/RocketIcon.svg";
import { MaterialType } from "shared/types";
import { truncateText } from "../../utils/truncateText";
import Img2 from "../../shared/ui/icon/Lock.svg";
import { ReactComponent as BrokenLock } from "shared/ui/icon/BrokenLock.svg";
import { MaterialDependences } from "./MaterialDependences";

interface Props {
    material: MaterialType;
}

export const MaterialItemBlocked: React.FC<Props> = ({ material }) => {
    const [hovered, sethovered] = useState(false);
    const active = false;
    const [showPopup, setShowPopup] = useState(false);

    return (
        <div
            onMouseEnter={() => sethovered(true)}
            onMouseLeave={() => sethovered(false)}
        >
            <ElementNew className="">
                <div className="flex h-full w-full min-w-[200px] max-w-[334px] items-center rounded-l-md px-[14px] py-[15px]">
                    <div className="flex flex-col">
                        <p className="flex items-end text-[14px] font-medium text-[#F37D73] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            {material?.content_type?.verbose_name}
                        </p>
                        <p className="flex max-h-[48px] min-h-[36px] items-end text-[16px] font-semibold text-textblack max-[1010px]:text-[12.32px] max-[1010px]:leading-[14.79px]">
                            {truncateText(material?.name, 1.4)}
                        </p>
                    </div>
                </div>
                {hovered === false ? (
                    <img src={LineBlue} alt="lineBlue" />
                ) : (
                    <img src={LineRed} alt="lineRed" />
                )}
                <div className="relative flex w-full max-w-[715px] flex-col px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <div className="mb-[24px] flex w-full max-w-[348px] justify-start text-end max-[1010px]:mb-[12px]">
                        <BrokenLock className="w-[22px] max-[1010px]:w-[16px]" />
                        <p className="ml-[5px] mt-[5px] text-sm font-medium leading-[16.8px] text-[#F37D73] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Заблокировано
                        </p>
                    </div>
                    <div className="flex">
                        <div className="flex w-[200px] flex-col">
                            <p className="mb-[38px] text-[12px] font-medium leading-[14.4px] text-[#264354B2] max-[1010px]:mb-[20px] max-[1010px]:text-[8.92px] max-[1010px]:leading-[10.7px]">
                                Прогресс изучения материала
                            </p>
                            <ProgressBar
                                percent={
                                    material?.session?.percent_progress || 0
                                }
                                background="#F37D73"
                                color="#F37D73"
                                height="3px"
                                fontSize=""
                                lineHeight=""
                                top=""
                                right=""
                            />
                        </div>
                    </div>
                </div>
                <>
                    {hovered === false ? (
                        <img src={LineBlue} alt="lineBlue" />
                    ) : (
                        <img src={LineRed} alt="lineRed" />
                    )}
                </>
                <div className="relative flex w-full max-w-[348px] flex-col items-start overflow-hidden px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <p className="mb-[10px] text-[14px] font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                        Достижения за прохождение
                    </p>
                    {active ? (
                        <div className="flex">
                            <img
                                className="transform opacity-[0.7] max-[1010px]:h-[80px] max-[1010px]:w-[100px]"
                                src={Img}
                                alt="Картинка"
                            />
                            <div>
                                <p className="mb-[4px] text-[16px] font-medium leading-[19.2px] text-[#F37D73] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                                    Буквоед
                                </p>
                                <p className="text-[12px] font-medium leading-[14.4px] text-[#264354B2] max-[1010px]:text-[8.92px] max-[1010px]:leading-[10.7px]">
                                    Вы прочитали статью 1
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex h-full w-full max-w-[232px] items-center justify-center">
                            <p className="text-center text-[14px] font-medium leading-[16.8px] text-[#73BDE9] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                                Достижения за прохождение этого материала не
                                добавлено
                            </p>
                            <img
                                className="absolute bottom-[-25px] right-[-15px] h-[136.66px] w-[112.06px] transform opacity-[0.7]"
                                src={Img2}
                                alt="Замок"
                            />
                        </div>
                    )}
                </div>
            </ElementNew>
            {showPopup && (
                <MaterialDependences
                    setShowPopup={setShowPopup}
                    activeId={material.id}
                />
            )}
        </div>
    );
};
