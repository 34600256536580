import React from "react";

interface ButtonStartProps {
    text: string;
    handleClickLink: () => void;
}

export const ButtonStart: React.FC<ButtonStartProps> = ({
    text,
    handleClickLink,
}) => {
    return (
        <button onClick={handleClickLink} className="z-[1]">
            <div className="flex h-[34px] items-center justify-center rounded-full bg-blue px-7 max-[1010px]:h-[25px]">
                <p className="text-[14px] font-medium leading-[14px] text-white max-[1010px]:text-[8.92px] max-[1010px]:leading-[8.92px]">
                    {text} &#8594;
                </p>
            </div>
        </button>
    );
};
