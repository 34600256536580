import { useParams, Link } from "react-router-dom";
import girl from "../shared/ui/icon/girl-error.svg";

type error = {
    title: string;
    description: string;
};

const errors: { [id: number]: error } = {
    400: {
        title: "Bad request",
        description:
            "Запрос не удалось обработать из-за синтаксической ошибки. Клиенту не следует повторять такой запрос без изменений.",
    },
    401: {
        title: "Unauthorized",
        description: "Запрос требует авторизации пользователя.",
    },
    403: {
        title: "Forbidden",
        description: "У вас нет доступа к этой странице.",
    },
    404: {
        title: "Not Found",
        description: "Похоже, что такой страницы не существует.",
    },
    405: {
        title: "Method Not Allowed",
        description: "Метод не разрешен.",
    },
    406: {
        title: "Not Acceptable",
        description: "Не найден контент, отвечающий критериям запроса.",
    },
    407: {
        title: "Proxy Authentication Required",
        description: "Необходимо авторизоваться на прокси-сервере.",
    },
    408: {
        title: "Request Timeout",
        description: "Превышено время ожидания запроса",
    },
    409: {
        title: "Conflict",
        description: "Конфликт запроса с текущим состоянием сервера",
    },
    410: {
        title: "Gone",
        description:
            "Требуемый ресурс больше не доступен на сервере и адрес его расположения неизвестен.",
    },
    411: {
        title: "Length Required",
        description:
            "Запрос отклонен, потому что сервер требует указания заголовка Content-Lenght.",
    },
    412: {
        title: "Precondition Failed",
        description: "Указанные условия не могут быть выполнены сервером.",
    },
    413: {
        title: "Request Entity Too Large",
        description: "Тело запроса слишком большое.",
    },
    414: {
        title: "Request-URI Too Long",
        description: "Указанный URI слишком длинный.",
    },
    415: {
        title: "Unsupported Media Type",
        description: "Формат запрашиваемых данных не поддерживается сервером.",
    },
    416: {
        title: "Requested Range Not Satisfiable",
        description:
            "Диапазон указанный заголовком запроса не может быть выполнен; возможно, он выходит за пределы переданного URL",
    },
    417: {
        title: "Expectation Failed",
        description:
            "Сервер не может удовлетворить значению поля Expect заголовка запроса.",
    },
    418: { title: "I am a teapot", description: "" },
    500: {
        title: "Internal Server Error",
        description:
            "Сервер столкнулся с неожиданными условиями, которые не позволили ему обработать запрос.",
    },
    501: {
        title: "Not Implemented",
        description:
            "Сервер не поддерживает функциональных возможностей, необходимых для выполнения запроса.",
    },
    502: {
        title: "Bad Gateway",
        description: "Один из внутренних сервисов временно недоступен.",
    },
    503: {
        title: "Service Unavailable",
        description:
            "Сервер не может обработать запрос из-за временной перегрузки или технических работ.",
    },
    504: {
        title: "Gateway Timeout",
        description: "Превышено время ожидания внутреннего сервиса.",
    },
    505: {
        title: "HTTP Version Not Supported",
        description:
            "HTTP-версия, используемая в запросе, не поддерживается сервером.",
    },
};

function ErrorPage() {
    const { id } = useParams();
    let first = "";
    let second = "";
    if (errors[id]) {
        if (id[1] === "0") {
            first = id.substring(0, 2);
            second = id.substring(2, 3);
        } else {
            first = id.substring(0, 1);
            second = id.substring(1, 3);
        }
    }

    return (
        <main className="flex h-screen">
            {errors[id] ? (
                <div className="relative mt-[20%] flex grow flex-col items-center">
                    <p className="mb-0 text-center text-[48px] font-bold leading-[57.6px] text-textblack">
                        {first}
                        {second}
                    </p>
                    <p className="mt-[30px] max-w-[1220px] p-[10px] text-center text-[16px] font-medium leading-[19.2px] text-[#264354] max-[834px]:mt-[10px]">
                        {errors[id].description}
                    </p>
                    <Link
                        to="/"
                        className="mt-[20px] w-full max-w-[238px] rounded-full bg-blue py-[14px] text-center text-[14px] leading-[16.8px] text-white hover:bg-gray-700"
                    >
                        <span className="px-4 font-normal">На главную</span>
                    </Link>
                    <img
                        src={girl}
                        alt="картинка"
                        className="absolute bottom-[-15%] z-[-1]"
                    />
                </div>
            ) : (
                <div className="flex grow flex-col items-center justify-center">
                    <p className="mb-0 text-center text-[110px] font-bold leading-[355px] text-textblack">
                        Данной страницы не существует.
                    </p>
                    <Link
                        to="/"
                        className="mt-[80px] w-[300px] rounded-full bg-blue py-[18px] text-center text-ext-xl leading-[28px] text-white hover:bg-gray-700"
                    >
                        <span className="px-4 font-normal">На главную</span>
                    </Link>
                </div>
            )}
        </main>
    );
}

export default ErrorPage;
