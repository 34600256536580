import React from "react";
import { useNavigate } from "react-router-dom";

export const Article = () => {
    const navigate = useNavigate();

    const handleClickLink = () => {
        navigate("/");
    };

    return (
        <main className="relative flex h-screen w-full flex-col overflow-hidden">
            <section className="relative flex h-screen w-full flex-col items-center overflow-hidden">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] grow items-center justify-between px-[16px]">
                    <div className="flex-flex-col">
                        <div className="flex flex-col items-center gap-[19px]">
                            <p className="textLink" onClick={handleClickLink}>
                                &#8592; Главная
                            </p>
                            <p className="text-[20px] font-bold leading-[24px] text-[#F37D73]">
                                Статья
                            </p>
                            <p className="text-[]">
                                Основы финансовой грамотности
                            </p>
                            <p>
                                Данный материал изучить и законспектировать в
                                электронном виде. прикрепить к заданию.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};
