import { truncateText } from "utils/truncateText";
import * as types from "shared/types";
import { useSessionPopup } from "components/MaterialSessionPopup/model";

interface Props {
    material: types.MaterialType;
    setEditMaterial: React.Dispatch<
        React.SetStateAction<types.MaterialType | undefined>
    >;
    threatId: number;
    contentList: types.MaterialType[];
    index: number;
    threatOrdered: boolean;
    available: boolean;
}

export const MaterialCard: React.FC<Props> = ({
    material,
    setEditMaterial,
    threatId,
    contentList,
    index,
    threatOrdered,
    available,
}) => {
    const openActiveMaterial = useSessionPopup((state) => state.open);

    if (threatOrdered === false) {
        available = true;
    }

    const handleClickLinkActive = (threatId: number, materialId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid activeId");
        }
        if (available === true) {
            openActiveMaterial(null, threatId, materialId);
        }
    };

    function openCard(evt: React.MouseEvent<HTMLDivElement>) {
        evt.stopPropagation();
        setEditMaterial(material);
    }

    return (
        <div
            className="relative flex h-[160px] max-w-[775px] justify-between rounded pb-[20px] pl-[30px] pr-[25px] pt-[25px] shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
            onClick={openCard}
        >
            <div className="flex w-full flex-col justify-between">
                <div className="flex w-full justify-between">
                    <div className="flex items-center gap-x-[5px]">
                        {/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.25 4.5C5.25 4.91421 4.91421 5.25 4.5 5.25C4.08579 5.25 3.75 4.91421 3.75 4.5C3.75 4.08579 4.08579 3.75 4.5 3.75C4.91421 3.75 5.25 4.08579 5.25 4.5Z" fill="#F37D73"/>
                            <path d="M7.5 4.5C7.5 4.91421 7.16421 5.25 6.75 5.25C6.33579 5.25 6 4.91421 6 4.5C6 4.08579 6.33579 3.75 6.75 3.75C7.16421 3.75 7.5 4.08579 7.5 4.5Z" fill="#F37D73"/>
                            <path d="M9.75 4.5C9.75 4.91421 9.41421 5.25 9 5.25C8.58579 5.25 8.25 4.91421 8.25 4.5C8.25 4.08579 8.58579 3.75 9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5Z" fill="#F37D73"/>
                            <path d="M1.5 7.125H16.5" stroke="#566DA3" stroke-linecap="round"/>
                            <path d="M6.75 15.75L6.75 7.5" stroke="#566DA3" stroke-linecap="round"/>
                            <path d="M1.5 9C1.5 5.46447 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46447 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4016 2.59835C16.5 3.6967 16.5 5.46447 16.5 9C16.5 12.5355 16.5 14.3033 15.4016 15.4017C14.3033 16.5 12.5355 16.5 9 16.5C5.46447 16.5 3.6967 16.5 2.59835 15.4017C1.86804 14.6713 1.62333 13.6451 1.54133 12" stroke="#566DA3" stroke-linecap="round"/>
                        </svg> */}
                        <div
                            className={`pt-[3px] text-[14px] font-bold leading-[16.8px] ${
                                available
                                    ? "text-[#566DA3]"
                                    : "text-gray-500 opacity-60"
                            } `}
                        >
                            {material.content_type.verbose_name}
                        </div>
                    </div>
                </div>

                <p
                    className={`text-tr-m font-medium ${
                        available ? "text-main-dark" : "text-gray-500"
                    }  opacity-60`}
                >
                    {truncateText(material.name, 2)}
                </p>
                <div className="flex w-full items-end justify-end pt-[15px]">
                    {/* <div className="flex flex-col gap-y-[10px]">
                        <p className="text-tr-s text-main-dark font-medium">Время изучения</p>
                        <div className="flex items-center justify-center h-[25px] w-full max-w-[145px] bg-[#D6D6D6] text-tr-s font-normal text-[#707070] rounded">
                            2 часа 15 минут
                        </div>
                    </div> */}
                    <div
                        onClick={() =>
                            handleClickLinkActive(threatId, material.id)
                        }
                        className={`group ${
                            available
                                ? "text-[#566DA3] hover:bg-[#566DA3] hover:text-white"
                                : "text-gray-500 opacity-60"
                        } flex h-[35px] w-full max-w-[140px] items-center justify-center gap-x-[10px] rounded-full shadow-[0_0_10px_0_rgba(38,67,84,0.1)]`}
                    >
                        <p className="text-tr-s font-medium">Изучить</p>
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.75 3.34471C7.43364 3.12726 8.18282 3 9 3C12.1364 3 14.271 4.87467 15.5438 6.52825C16.1813 7.35639 16.5 7.77047 16.5 9C16.5 10.2295 16.1813 10.6436 15.5438 11.4718C14.271 13.1253 12.1364 15 9 15C5.86359 15 3.72897 13.1253 2.45617 11.4718C1.81872 10.6436 1.5 10.2295 1.5 9C1.5 7.77047 1.81872 7.3564 2.45617 6.52825C2.81709 6.05936 3.2473 5.57269 3.75 5.11603"
                                stroke="#566DA3"
                                className={` ${
                                    available
                                        ? "group-hover:stroke-white"
                                        : "stroke-white-gray-500 opacity-60"
                                }`}
                                stroke-linecap="round"
                            />
                            <path
                                d="M11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9Z"
                                stroke="#566DA3"
                                className={` ${
                                    available
                                        ? "group-hover:stroke-white"
                                        : "stroke-white-gray-500 opacity-60"
                                }`}
                            />
                        </svg>
                    </div>
                </div>
            </div>
            {threatOrdered === true && (
                <div className="ml-[10px] flex flex-col items-center gap-y-[10px]">
                    <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.2646 2.37603C12.4498 1.18617 14.2754 1.08972 15.3421 2.16058C16.4089 3.23145 16.3128 5.06411 15.1275 6.25396L13.4106 7.97751M7.6162 9.9165C6.54948 8.84564 6.64556 7.01296 7.83081 5.8231L9.35417 4.29384"
                            stroke="#F37D73"
                            stroke-width="1.0625"
                            stroke-linecap="round"
                        />
                        <path
                            d="M10.3831 7.0835C11.4499 8.15437 11.3538 9.98701 10.1685 11.1769L8.45167 12.9004L6.7348 14.624C5.54954 15.8138 3.72395 15.9103 2.65722 14.8394C1.59049 13.7685 1.68658 11.9359 2.87183 10.746L4.58872 9.02245"
                            stroke="#F37D73"
                            stroke-width="1.0625"
                            stroke-linecap="round"
                        />
                    </svg>
                    {index + 1 !== contentList.length &&
                    threatOrdered === true ? (
                        <svg
                            width="8"
                            height="102"
                            viewBox="0 0 8 102"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.64644 101.354C3.8417 101.549 4.15829 101.549 4.35355 101.354L7.53553 98.1716C7.73079 97.9763 7.73079 97.6597 7.53553 97.4645C7.34027 97.2692 7.02369 97.2692 6.82842 97.4645L4 100.293L1.17157 97.4645C0.976306 97.2692 0.659724 97.2692 0.464462 97.4645C0.2692 97.6597 0.2692 97.9763 0.464462 98.1716L3.64644 101.354ZM3.5 -2.18557e-08L3.5 2.525L4.5 2.525L4.5 2.18557e-08L3.5 -2.18557e-08ZM3.5 7.575L3.5 12.625L4.5 12.625L4.5 7.575L3.5 7.575ZM3.5 17.675L3.5 22.725L4.5 22.725L4.5 17.675L3.5 17.675ZM3.5 27.775L3.5 32.825L4.5 32.825L4.5 27.775L3.5 27.775ZM3.5 37.875L3.5 42.925L4.5 42.925L4.5 37.875L3.5 37.875ZM3.5 47.975L3.5 53.025L4.5 53.025L4.5 47.975L3.5 47.975ZM3.5 58.075L3.5 63.125L4.5 63.125L4.5 58.075L3.5 58.075ZM3.5 68.175L3.5 73.225L4.5 73.225L4.5 68.175L3.5 68.175ZM3.5 78.275L3.5 83.325L4.5 83.325L4.5 78.275L3.5 78.275ZM3.5 88.375L3.5 93.425L4.5 93.425L4.5 88.375L3.5 88.375ZM3.5 98.475L3.5 101L4.5 101L4.5 98.475L3.5 98.475Z"
                                fill="#F37D73"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="6"
                            height="104"
                            viewBox="0 0 6 104"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 98.3333C1.52724 98.3333 0.333329 99.5272 0.333329 101C0.333329 102.473 1.52724 103.667 3 103.667C4.47275 103.667 5.66666 102.473 5.66666 101C5.66666 99.5272 4.47276 98.3333 3 98.3333ZM2.5 -2.18557e-08L2.5 2.525L3.5 2.525L3.5 2.18557e-08L2.5 -2.18557e-08ZM2.5 7.575L2.5 12.625L3.5 12.625L3.5 7.575L2.5 7.575ZM2.5 17.675L2.5 22.725L3.5 22.725L3.5 17.675L2.5 17.675ZM2.5 27.775L2.5 32.825L3.5 32.825L3.5 27.775L2.5 27.775ZM2.5 37.875L2.5 42.925L3.5 42.925L3.5 37.875L2.5 37.875ZM2.5 47.975L2.5 53.025L3.5 53.025L3.5 47.975L2.5 47.975ZM2.5 58.075L2.5 63.125L3.5 63.125L3.5 58.075L2.5 58.075ZM2.5 68.175L2.5 73.225L3.5 73.225L3.5 68.175L2.5 68.175ZM2.5 78.275L2.5 83.325L3.5 83.325L3.5 78.275L2.5 78.275ZM2.5 88.375L2.5 93.425L3.5 93.425L3.5 88.375L2.5 88.375ZM2.5 98.475L2.5 101L3.5 101L3.5 98.475L2.5 98.475Z"
                                fill="#F37D73"
                            />
                        </svg>
                    )}
                </div>
            )}
        </div>
    );
};
