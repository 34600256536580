import React from "react";
import "./NavbarNotification.css";
import useEvents from "shared/api/useEvents";
import * as types from "shared/types";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
interface NavbarNotificationProps {
    openNotifications: boolean;
    toggleNotifications: () => void;
}

const EventModel = CreatePaginatedModel<types.Event>("Event");

const ModelApi = CreateModelApi(EventModel, `/api/v1/user/logs/list`, "", 10);

export const NavbarNotification: React.FC<NavbarNotificationProps> = ({
    openNotifications,
    toggleNotifications,
}) => {
    const { data } = useEvents();

    return (
        <>
            <div
                className={`navbarNotification ${
                    openNotifications ? "open" : ""
                }`}
            >
                <div className="navbarNotificationInfo">
                    <div className="navbarNotificationContainerInfo">
                        <h4 className="navbarNotificationTitle">Уведомления</h4>
                        {data?.results?.length > 0 ? (
                            <div className="navbarNotificationContainer">
                                {data.results.map((event, key) => (
                                    <div className="itemNotification" key={key}>
                                        <p className="textNotification">
                                            {new Date(
                                                event.time_create
                                            ).toLocaleString()}
                                        </p>
                                        <p className="">{event.content}</p>
                                    </div>
                                ))}
                                {data.next && (
                                    <button
                                        className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                                        onClick={() =>
                                            ModelApi.appendNextPage(data)
                                        }
                                    >
                                        Загрузить еще
                                    </button>
                                )}
                            </div>
                        ) : (
                            <div className="ml-[15px]">Уведомлений нет</div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`backdrop ${openNotifications ? "show" : ""}`}
                onClick={toggleNotifications}
            ></div>
        </>
    );
};
