import React from "react";

export const ButtonsArrow = () => {
    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };
    return (
        <div className="flex gap-[53px]">
            <button
                className="h-[30px] w-[30px] rounded-full border-2 border-[#264354B2] text-[18px] text-[#264354B2]"
                // disabled={currentPage === 1}
                onClick={scrollLeft}
            >
                &#8249;
            </button>
            <button
                className="h-[30px] w-[30px] rounded-full border-2 border-[#264354B2] text-[18px] text-[#264354B2]"
                //disabled={goalsList.count % 2 === 0}
                onClick={scrollRight}
            >
                &#8250;
            </button>
        </div>
    );
};
