import { useParams } from "react-router-dom";
import { TitleLink } from "../../components/TitleLink/TitleLink";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import { MaterialItem } from "../../components/MaterialItem/MaterialItem";
import * as types from "shared/types";
import * as http from "shared/http";
import { Loader } from "components/Loader/Loader";

async function getActiveEdProgram(programId: number | string) {
    return http.request<types.EdProgram>({
        method: "get",
        url: `/api/v1/ed_programs/${programId}`,
    });
}

const ContentModel =
    CreatePaginatedModel<types.ProgramContent>("ProgramContent");

function Material() {
    const { programId } = useParams<{ programId: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [edProgram, setEdProgram] = useState<types.EdProgram>();
    const materials = useStore(ContentModel.store);
    const [width, setWidth] = useState(window.innerWidth);

    const ModelApi = CreateModelApi(
        ContentModel,
        `/api/v1/user/ed_programs/${programId}/content`,
        "",
        10
    );

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    useEffect(() => {
        if (programId !== undefined) {
            getActiveEdProgram(programId)
                .then(setEdProgram)
                .then(ModelApi.loadFirstPage)
                .then(() => setIsLoading(false));
        }
    }, [programId, ModelApi]);

    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-screen w-full flex-col items-center overflow-y-scroll">
            <TitleLink title={edProgram.name} />
            {materials?.results?.length > 0 ? (
                <ul className="flex h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px]">
                    {materials.results.map((material, key) => (
                        <li
                            className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                            key={key}
                            children={<MaterialItem material={material} />}
                        />
                    ))}
                    {materials.next && (
                        <button
                            className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                            onClick={() => ModelApi.appendNextPage(materials)}
                        >
                            Загрузить еще
                        </button>
                    )}
                </ul>
            ) : (
                <p className="flex justify-center text-center text-[25px] font-bold">
                    Ваши учебные профили формируются
                </p>
            )}
        </section>
    );
}

export default Material;
