import axios from "axios";
import { Loader } from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { saveTokens } from "shared/http";

type FormUser = {
    first_name: string;
    surname: string;
    last_name: string;
    position: string;
};

function RegistrationForm() {
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get("key");
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const { reset, register, handleSubmit } = useForm<FormUser>({
        defaultValues: {
            first_name: "",
            surname: "",
            last_name: "",
            position: "",
        },
    });

    useEffect(() => {
        setIsLoading(true);
        axios(`/api/v1/continue_registration/${key}`)
            .then((res) => {
                if (res.data) {
                    reset(res.data);
                }
                setIsLoading(false);
            })
            .catch(() => navigate("/error/404"));
    }, [key]);

    function submitHandler(data: any) {
        console.log(data);
        console.log("key ", key);

        axios({
            headers: {
                "content-type": "application/json",
            },
            method: "put",
            url: `/api/v1/continue_registration/${key}`,
            data: data,
        }).then((res) => {
            saveTokens(res?.data?.access, res?.data?.refresh);
            toast.success("Регистрация прошла успешно!");

            axios({
                headers: {
                    "content-type": "application/json",
                },
                method: "post",
                url: `/api/v1/auth/jwt/verify`,
                data: {
                    token: res.data.access,
                },
            })
                .then(() => {
                    navigate("/");
                    window.location.reload();
                })
                .catch(() => {
                    navigate("/login");
                });
        });
    }

    if (isLoading) {
        return (
            <div className="h-screen">
                <Loader text="Загрузка..." />;
            </div>
        );
    }

    return (
        <form
            onSubmit={handleSubmit(submitHandler)}
            className="m-auto flex w-[300px] flex-col md:w-[350px] lg:w-[400px] xl:w-[550px]"
        >
            <p className="mb-4 text-3xl text-blue">Добро пожаловать!</p>
            <p className="text-lg">Заполните Ваши данные:</p>

            <label htmlFor="firstname" className="mt-4">
                Имя:
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm rounded bg-blue1 p-2 shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] ring-1 ring-transparent focus:outline-none focus:ring-blue4 hover:ring-2"
                    id="firstname"
                    type="text"
                    {...register("first_name")}
                />
            </div>

            <label htmlFor="surname" className="mt-4">
                Фамилия:
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm rounded bg-blue1 p-2 shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] ring-1 ring-transparent focus:outline-none focus:ring-blue4 hover:ring-2"
                    id="surname"
                    type="text"
                    {...register("surname")}
                />
            </div>

            <label htmlFor="lastname" className="mt-4">
                Отчество:
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm rounded bg-blue1 p-2 shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] ring-1 ring-transparent focus:outline-none focus:ring-blue4 hover:ring-2"
                    id="lastname"
                    type="text"
                    {...register("last_name")}
                />
            </div>

            <label htmlFor="position" className="mt-4">
                Должность:
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm rounded bg-blue1 p-2 shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] ring-1 ring-transparent focus:outline-none focus:ring-blue4 hover:ring-2"
                    id="position"
                    type="text"
                    {...register("position")}
                />
            </div>
            <div>
                <button
                    type="submit"
                    className="group relative mt-6 flex rounded bg-blue px-16 py-3 text-white hover:bg-[#72BCE833] hover:text-blue hover:opacity-[15] hover:shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] "
                >
                    Продолжить
                </button>
            </div>
        </form>
    );
}

export default RegistrationForm;
