import { useState, useEffect } from "react";
import { GoalItem } from "../../components/GoalItem/GoalItem";
import { TitleLink } from "../../components/TitleLink/TitleLink";
import useTrajectory_actives from "shared/api/useTrajectory_actives";
import { Loader } from "components/Loader/Loader";
import { ReactComponent as Cup } from "shared/ui/icon/cup.svg";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import * as types from "shared/types";
import { GoalItemBlocked } from "components/GoalItem/GoalItemBlocked";
import { GoalItemSmall } from "components/GoalItem/GoalItemSmall";
import { GoalItemSmallBlocked } from "components/GoalItem/GoalItemSmallBlocked";
import { OrdersPieChartActives } from "components/OrdersPieChart/OrdersPieChartActives";
import useMaterialsLib from "shared/api/useMaterialsLib";
import { useNavigate } from "react-router-dom";
import useUserInfo from "shared/api/useUserInfo";

const ProfileActivesModel =
    CreatePaginatedModel<types.TrajectoryActiveType>("ProfileActives");

function Goals() {
    const { data, isLoading } = useTrajectory_actives();
    const [width, setWidth] = useState(window.innerWidth);
    const { userData } = useUserInfo();
    const allMaterial = useMaterialsLib();
    const navigate = useNavigate();

    const ModelApi = CreateModelApi(
        ProfileActivesModel,
        `/api/v1/user/trajectory_actives`,
        "",
        10
    );
    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    // TODO добавить крутилку
    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <section className="flex w-full flex-col items-center overflow-y-auto overflow-x-hidden">
            <div className="relative flex w-full max-w-[1415px] flex-col">
                <TitleLink title="Ваши текущие цели" />
                {data?.results?.length > 0 ? (
                    <ul className="flex h-full w-full flex-col items-center overflow-y-scroll pb-[10px] max-[834px]:h-[500px]">
                        <div className="mb-[20px] hidden w-full px-[16px] max-[834px]:flex">
                            <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] max-[1010px]:text-[12.32px] max-[1010px]:leading-[14.79px]">
                                Прогресс по достижению целей
                            </p>
                        </div>
                        <div className="mb-[40px] mt-[20px] flex w-full max-w-[1415px] gap-[21px] pl-[16px] max-[1010px]:mb-[20px] max-[834px]:mb-[60px]">
                            <div className="h-[219px] w-[20%] max-w-[219px] max-[1010px]:h-[119px] max-[834px]:h-[169px] max-[834px]:w-[80%]">
                                <OrdersPieChartActives data={userData} />
                            </div>
                            <div className="flex w-full max-w-[698px] flex-col justify-between">
                                <p className="text-[20px] font-medium leading-[24px] text-[#264354CC] max-[1010px]:text-[12.32px] max-[1010px]:leading-[14.79px] max-[834px]:hidden">
                                    Прогресс по достижению целей
                                </p>
                                <div className="flex justify-between">
                                    <div className="flex h-[86px] flex-col justify-between max-[1010px]:h-[70px]">
                                        <p className="text-[16px] font-medium leading-[19.2px] text-[#264354CC] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                                            Всего учебных материалов
                                        </p>
                                        <div className="flex w-full items-center gap-[12px]">
                                            <p className="text-[32px] font-medium leading-[38.4px] text-[#566DA3] max-[1010px]:text-[23.79px] max-[1010px]:leading-[28.54px]">
                                                {
                                                    allMaterial?.data?.results
                                                        ?.length
                                                }
                                            </p>
                                            <button
                                                className="mt-[10px] flex h-[12px]"
                                                onClick={() =>
                                                    navigate("/materials")
                                                }
                                            >
                                                <p className="text-[12px] font-medium leading-[12px] text-[#264354B2] max-[1010px]:text-[8.92px] max-[1010px]:leading-[8.92px]">
                                                    Открыть список &#8594;
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Cup className="absolute right-[-50px] top-0 h-full max-h-[415px] w-full max-w-[467px] opacity-50 max-[834px]:hidden" />
                        </div>
                        {data.results.map((profile, key) =>
                            profile.is_super === true ? (
                                width > 834 ? (
                                    <li
                                        className="mt-[20px] w-full max-w-[1415px] px-[16px] max-[834px]:mt-[10px]"
                                        key={key}
                                        children={
                                            <GoalItemBlocked active={profile} />
                                        }
                                    />
                                ) : (
                                    <li
                                        className="mt-[20px] w-full max-w-[1415px] px-[16px] max-[834px]:mt-[10px]"
                                        key={key}
                                        children={
                                            <GoalItemSmallBlocked
                                                active={profile}
                                            />
                                        }
                                    />
                                )
                            ) : width > 834 ? (
                                <li
                                    className="z-[1] mt-[20px] w-full max-w-[1415px] px-[16px] max-[834px]:mt-[10px]"
                                    key={key}
                                    children={<GoalItem active={profile} />}
                                />
                            ) : (
                                <li
                                    className="mt-[20px] w-full max-w-[1415px] px-[16px] max-[834px]:mt-[10px]"
                                    key={key}
                                    children={
                                        <GoalItemSmall active={profile} />
                                    }
                                />
                            )
                        )}
                        {data.next && (
                            <button
                                className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                                onClick={() => ModelApi.appendNextPage(data)}
                            >
                                Загрузить еще
                            </button>
                        )}
                    </ul>
                ) : (
                    <p className="flex justify-center text-center text-[25px] font-bold">
                        Ваши учебные профили формируются
                    </p>
                )}
            </div>
        </section>
    );
}

export default Goals;
