import { Routes, Route, Navigate } from "react-router-dom";
import { useStore } from "effector-react";
import * as api from "shared/http";
import "shared/ws/notifications";
import IsMobileLayout from "layouts/IsMobileLayout";
import UserLayout from "layouts/UserLayout";
import Registration from "pages/registration";
import Authorization from "pages/login";
import ErrorPage from "pages/error";
import Session from "pages/session";
import Material from "pages/trajectory/Material";
import Skills from "pages/trajectory/Skills";
import { Article } from "pages/trajectory/Article";
import MaterialsLib from "pages/trajectory/MaterialsLib";
import SkillsLib from "pages/trajectory/SkillsLib";
import Goals from "pages/trajectory/Goals";
import "./index.css";
import MainPage from "pages/trajectory/MainPage";
import ThreatMaterials from "pages/trajectory/ThreatMaterials";
import { Loader } from "components/Loader/Loader";
import Timeline from "pages/trajectory/Timeline";
import { ProgressLib } from "pages/trajectory/ProgressLib";
import ResetPasswordPage from "pages/ResetPasswordForm";

function App() {
    const tokenState = useStore(api.$tokenState);
    const isErrorPage = window.location.pathname.startsWith("/error/");

    if (tokenState === "loading" && !isErrorPage)
        return (
            <div className="h-screen">
                <Loader text="Загрузка..." />;
            </div>
        );

    return (
        <IsMobileLayout>
            <Routes>
                {tokenState === "valid" ? (
                    <Route path="/" element={<UserLayout />}>
                        <Route index element={<MainPage />} />
                        <Route
                            path="login"
                            element={<Navigate to="/" replace={true} />}
                        />
                        <Route path="user/skills" element={<Skills />} />
                        <Route
                            path="program/:programId"
                            element={<Material />}
                        />
                        <Route path="session/:id" element={<Session />} />
                        <Route path="materials" element={<MaterialsLib />} />
                        <Route path="goals" element={<Goals />} />
                        <Route path="article" element={<Article />} />
                        <Route path="skills" element={<SkillsLib />} />
                        <Route path="skills/:id" element={<Skills />} />
                        <Route path="progress" element={<ProgressLib />} />
                        <Route path="timeline/:goalId" element={<Timeline />} />
                        <Route
                            path="skill/:skillId"
                            element={<ThreatMaterials />}
                        />
                    </Route>
                ) : (
                    <>
                        <Route path="/" element={<Authorization />} />
                        <Route path="/login" element={<Authorization />} />
                        <Route
                            path="/registration"
                            element={<Registration />}
                        />
                        <Route
                            path="/reset-password"
                            Component={ResetPasswordPage}
                        />
                    </>
                )}
                <Route
                    /* нужно только для разработки */
                    path="/ui"
                    element={<Navigate to="/" replace={true} />}
                />
                <Route path="/error/:id" element={<ErrorPage />} />
                <Route
                    path="*"
                    element={<Navigate to="/error/404" replace={true} />}
                />
            </Routes>
        </IsMobileLayout>
    );
}

export default App;
