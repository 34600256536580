import { ReactComponent as GirlDontKnow } from "shared/ui/icon/GirlDontKnow.svg";
import { getEvents, useStore, useStoreDate } from "./model";
import { useQuery } from "@tanstack/react-query";
import { Content } from "shared/types";
import { Loader } from "components/Loader/Loader";
import { useState } from "react";
import { current } from "immer";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import { ReactComponent as EyeIcon } from "../../shared/ui/icon/EyeIcon.svg";

function transformDate(date: Date) {
    const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
}

function EventItem({ content }: { content: Content }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const date = new Date(content.event_date);
    const dateString = transformDate(date);

    return (
        <>
        {/* <div
            data-isExpanded={isExpanded}
            className="group border-monochrome-light px-[20px] py-[10px] text-main-dark data-[isExpanded=true]:rounded-[6px] data-[isExpanded=false]:border-b data-[isExpanded=true]:shadow-darkBlue"
        >
            <div className="flex items-center">
                <div className="grow">
                    <p className="text-tr-m font-medium">{dateString}</p>
                    <p className="mt-[10px] text-tr-s font-medium">
                        {content.name}
                    </p>
                </div>
                <button
                    className="h-4 w-4"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? "⬆" : "⬇"}
                </button>
            </div>
            {isExpanded && (
                <div className="mt-[10px] font-normal">
                    {content.url && (
                        <a className="underline" href={content.url}>
                            Ссылка на событие
                            <br />
                        </a>
                    )}
                    {content.description}
                </div>
            )}
        </div> */}

        {isExpanded === false ? (
            <div className="flex justify-between w-full mt-[20px] items-center border-b-[1px] border-b-monochrome-light  py-[10px] px-5">
                <div className="flex flex-col w-full gap-y-[10px]">
                    <div className="flex gap-x-[5px]">
                        <p className="text-tr-m text-[#264354] font-medium" >{dateString}</p>
                        {/* <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center"> 
                            <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
                        </div> */}
                    </div>
                    <p className="text-tr-s text-[#264354] font-medium">{content.name}</p>
                </div>
                <ListOpen onClick={() => setIsExpanded(!isExpanded)} className="cursor-pointer"/>
            </div>
        ):(
            <div className="flex flex-col gap-y-[10px] mt-[20px]  py-[10px] px-5 shadow-[0px_0px_15px_0px_#2643541A] rounded-md">
                <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col w-full gap-y-[7px]">
                        <div className="flex gap-x-[5px]">
                            <p className="text-tr-m text-[#264354] font-medium" >{dateString}</p>
                            {/* <div className="flex w-full max-w-[355px] rounded bg-[#F37D73] bg-opacity-20 justify-center items-center"> 
                                <p className="text-tr-s text-[#F37D73] font-normal">приближается дедлайн по учебному материалу</p>
                            </div> */}
                        </div>
                        <p className="text-tr-s text-[#264354] font-medium mt-[3px]">{content.name}</p>
                    </div>
                    <ListClose onClick={() => setIsExpanded(!isExpanded)} className="cursor-pointer"/>
                </div>
                
                <div className="flex justify-between w-full items-center">
                    <div className="flex flex-col w-full gap-y-[7px]">
                        <p className="text-tr-s text-[#264354] font-medium">{content.content_type.custom_name}</p>
                        {/* <div className="flex gap-x-[5px]">
                            <p className="text-tr-m text-[#264354] font-medium" >Время прохождения</p>
                            <div className="flex w-full max-w-[70px] h-[20px] rounded bg-[#566DA333] bg-opacity-20 justify-center items-center"> 
                                <p className="text-tr-s text-[#264354] font-normal">15 минут</p>
                            </div>
                        </div> */}
                        
                    </div>
                    {/* <div className="group hover:bg-[#264354] hover:text-white flex w-full max-w-[140px] h-full max-h-[35px] rounded-full items-center justify-between shadow-[0px_0px_15px_0px_#2643541A] cursor-pointer px-[20px]">
                        Изучить
                        <EyeIcon className="fill-[#264354] group-hover:fill-white"/>
                    </div> */}
                    
                </div>
            </div>
        )}
        </>
        
    );
}

export function Events() {
    const selectedDay = useStore((s) => s.selectedDay);

    const { currentMonth, currentYear } = useStoreDate();
    const { data, status, error } = useQuery({
        queryKey: ["events-list", currentYear, currentMonth],
        queryFn: () => getEvents(currentYear, currentMonth + 1),
    });

    const dateString = new Date(
        currentYear,
        currentMonth,
        selectedDay
    ).toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });

    const filteredEvents =
        data?.filter((event) => {
            const eventDate = new Date(event.event_date);
            return (
                currentMonth === eventDate.getMonth() &&
                selectedDay === eventDate.getDate()
            );
        }) || [];

    return (
        <div className="col-span-2 hidden w-full max-h-[665px] flex-col min-[800px]:flex mt-[60px]">
            <p className="text-tr-m font-semibold text-[#264354] ">
                Все события
            </p>
            <p className="mt-[15px] text-tr-m font-normal text-[#264354]">
                {dateString}
            </p>

            <div className="overflow-y-auto -mx-[10px] px-[10px]">
                {status === "pending" || status === "error" ? (
                    <Loader text={"Загрузка..."} />
                ) : filteredEvents.length ? (
                    <ul className="space-y-[20px] py-[20px]">
                        {filteredEvents.map((content) => (
                            <EventItem content={content} key={content.id} />
                        ))}
                    </ul>
                ) : (
                    <div className="flex items-center">    
                        <GirlDontKnow className="ml-[20px] mt-[70px]" />
                        <p className="text-tr-m font-medium text-main-dark">
                            Событий не запланировано
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

