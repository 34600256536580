import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";

export function fetchMaterials() {
    return http.request<types.MaterialType[]>({
        method: "get",
        url: "/api/v1/user/content",
    });
};

export default function useMaterialsList() {
    const { data, error } = useSWR<types.MaterialType[], Error>("fetchMaterials", fetchMaterials);

    return {
        materialsList: data,
        isLoading: !error && !data,
        isError: error,
    };
};