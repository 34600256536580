import * as http from "shared/http";
import { UserInfo } from "shared/types";
import useSWR from "swr";

export function getUserInfo() {
    return http.request<UserInfo>({
        method: "get",
        url: "/api/v1/user/info",
    });
}

export default function useUserInfo() {
    const { data, error } = useSWR("getUserInfo", getUserInfo);

    return {
        userData: data,
        isLoading: !error && !data,
        isError: error,
    };
}
