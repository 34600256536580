import { useState } from "react";
import { useForm } from "react-hook-form";
import * as http from "shared/http";
import * as types from "shared/types";
import { ReactComponent as FullLogo2 } from "shared/image/icon/FullLogoIcon2.svg";
import { ReactComponent as EmailIcon } from "shared/image/icon/LoginInput.svg";
import { ReactComponent as PassIcon } from "shared/image/icon/PasswordInput.svg";
import { ReactComponent as Eye } from "shared/image/icon/EyeIcon.svg";
import { ReactComponent as EyeClosed } from "shared/image/icon/EyeClosed.svg";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

function LoginForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<types.AuthData>();
    const [type, setType] = useState("password");
    const [toggleIcon, settoggleIcon] = useState(
        <EyeClosed className="fill-blue" />
    );
    const togglePassInput = () => {
        if (type === "password") {
            setType("text");
            settoggleIcon(<Eye className="fill-blue" />);
        } else {
            setType("password");
            settoggleIcon(<EyeClosed className="fill-blue" />);
        }
    };

    async function submitHandler(data: types.AuthData) {
        try {
            await http.authWithCredentials(data);
        } catch (error) {
            if (
                error instanceof Error &&
                error.message === "Invalid credentials"
            ) {
                toast.error("Проверьте данные и попробуйте еще раз");
            } else {
                toast.error("Произошла ошибка при авторизации");
            }
        }
    }

    return (
        <form
            onSubmit={handleSubmit(submitHandler)}
            className="z-10 flex h-full w-full max-w-[520px] flex-col justify-center"
        >
            <FullLogo2 className="mb-[60px] w-full max-w-[350px] max-[1024px]:mb-[40px] max-[1024px]:h-[74px] max-[1024px]:w-[228px] max-[430px]:h-[66px] max-[430px]:w-[206px]" />
            <p className="mb-[60px] text-[20px] font-semibold leading-[23.7px] text-[#264354CC]">
                Добро пожаловать!
            </p>

            <label
                className="text-[16px] font-normal leading-[18.96px] text-[#264354B2]"
                htmlFor="email"
            >
                Электронная почта или телефон
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm border-b-[1px] border-blue border-opacity-100 bg-transparent bg-opacity-0 p-2 pl-8 focus:outline-none"
                    id="email"
                    placeholder="mail@example.com"
                    {...register("email", {
                        required: "Обязательно для заполнения",
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                                "Введите корректный адрес электронной почты",
                        },
                    })}
                    type="email"
                    autoFocus
                />
                <EmailIcon className="absolute top-4 left-0" />
            </div>
            {errors.email && (
                <span role="alert" className="text-ext-xs text-error">
                    {errors.email.message}
                </span>
            )}
            <label
                className="mt-4 text-[16px] font-normal leading-[18.96px] text-[#264354B2]"
                htmlFor="password"
            >
                Пароль
            </label>
            <div className="relative">
                <input
                    className="mt-1 w-full max-w-screen-sm border-b-[1px] border-blue bg-transparent p-2 pl-8 focus:outline-none"
                    id="password"
                    placeholder="Введите ваш пароль"
                    {...register("password", {
                        required: "Обязательно для заполнения",
                    })}
                    type={type}
                />

                <PassIcon className="absolute top-3 left-0" />
                <div
                    className="absolute top-3 right-2 cursor-pointer fill-blue"
                    onClick={togglePassInput}
                >
                    {toggleIcon}
                </div>
            </div>
            {errors.password && (
                <span role="alert" className="text-ext-xs text-error">
                    {errors.password.message}
                </span>
            )}
            <div className="flex items-end justify-between max-[1124px]:flex-col-reverse max-[1124px]:items-center">
                <Link to="/reset-password" className="cursor-pointer text-[14px] font-medium text-[#264354B2] underline max-[1124px]:mt-[10px]">
                    Не помню пароль
                </Link>
                <button
                    type="submit"
                    className="group relative mt-6 flex rounded-full bg-blue py-3 px-16 text-white hover:bg-[#72BCE833] hover:text-blue hover:opacity-[15] hover:shadow-[10px_10px_25px_0_rgba(114,188,232,0.2)] "
                >
                    Войти
                </button>
            </div>
        </form>
    );
}

export default LoginForm;
