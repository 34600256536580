import { CardProgressLib } from "components/CardProgressLib/CardProgressLib";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cup } from "shared/ui/icon/cup.svg";
import { ButtonsArrow } from "components/ButtonsArrow/ButtonsArrow";
import DemoButton from "shared/ui/buttons/DemoButton";
import useAchievements from "shared/api/useAchievements";
import { Achievements } from "components/Achievements/Achievements";

export const ProgressLib = () => {
    const navigate = useNavigate();
    const { achievementsList } = useAchievements();
    const handleClickLink = () => {
        navigate("/");
    };

    return (
        <main className="relative flex h-screen w-full flex-col overflow-auto">
            <section className="relative flex h-screen w-full flex-col items-center overflow-hidden">
                <div className="mb-[60px] mt-[30px] flex max-h-[61px] w-full max-w-[1415px] grow items-center justify-between px-[16px]">
                    <div className="flex-flex-col">
                        <div className="items-center">
                            <p className="textLink" onClick={handleClickLink}>
                                &#8592; Главная
                            </p>
                            <h2 className="title">Библиотека достижений</h2>
                        </div>
                    </div>
                </div>
                <Cup className="right-[calc(50% - 50px)] absolute top-0 z-[-1] mx-auto h-full max-h-[415px] w-full max-w-[467px]" />
                <div className="z-10 mb-[141px] flex w-full max-w-[1415px] items-center justify-between pl-[102px] pr-[16px]">
                    <div className="flex max-w-[375px] flex-col gap-[20px]">
                        <p className="text-[36px] font-bold leading-[43.2px] text-[#264354CC]">
                            Достигайте высот, изучая новое!
                        </p>
                        <button className="w-[238px] rounded-[50px] bg-[#F37D73] py-[13px] text-center text-[16px] font-medium leading-[19.2px] text-white">
                            <DemoButton>Вперед &#8594;</DemoButton>
                        </button>
                    </div>
                    <CardProgressLib />
                </div>
                <ul className="mb-[123px] flex h-full w-full max-w-[1415px] flex-col items-center space-y-[30px] px-[16px] pb-[20px]">
                    <h2 className="title w-full">В процессе</h2>
                    {achievementsList?.results?.length > 0 ? (
                        <>
                            <div className="flex w-full gap-[25px]">
                                {achievementsList?.results.map(
                                    (achievement) => {
                                        return (
                                            <DemoButton>
                                                <Achievements
                                                    achievement={achievement}
                                                />
                                            </DemoButton>
                                        );
                                    }
                                )}
                            </div>
                            <div className="relative mb-[60px] flex w-full max-w-[1415px] items-center justify-center">
                                <ButtonsArrow />
                                <DemoButton>
                                    <p className="absolute right-0 top-0 text-[14px] font-medium leading-[16.8px] text-[#264354B2]">
                                        Смотреть все &#8594;
                                    </p>
                                </DemoButton>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">Данных нет</div>
                    )}
                </ul>
                <ul className="flex h-full w-full max-w-[1415px] flex-col items-center space-y-[30px] px-[16px] pb-[20px]">
                    <h2 className="title w-full">Достигнуто</h2>
                    {achievementsList?.results?.length > 0 ? (
                        <>
                            <div className="flex w-full gap-[25px]">
                                {achievementsList?.results.map(
                                    (achievement) => {
                                        return (
                                            <DemoButton>
                                                <Achievements
                                                    achievement={achievement}
                                                />
                                            </DemoButton>
                                        );
                                    }
                                )}
                            </div>
                            <div className="relative mb-[60px] flex w-full max-w-[1415px] items-center justify-center">
                                <ButtonsArrow />
                                <DemoButton>
                                    <p className="absolute right-0 top-0 text-[14px] font-medium leading-[16.8px] text-[#264354B2]">
                                        Смотреть все &#8594;
                                    </p>
                                </DemoButton>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">Данных нет</div>
                    )}
                </ul>
            </section>
        </main>
    );
};
