import DemoButton from "shared/ui/buttons/DemoButton";

export const CardProgressLib = () => {
    return (
        <div className="flex w-full max-w-[575px] flex-col rounded-[10px] bg-white pb-[10px] pl-[20px] pr-[29px] pt-[20px] shadow-[0px_0px_15px_0px_#2643541A]">
            <div className="flex justify-between ">
                <div className="mb-[30px] flex flex-col gap-[10px]">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                        Достижения в профиле
                    </p>
                    <p className="text-[16px] font-medium leading-[19.2px] text-[#264354]">
                        Топ-менеджемент
                    </p>
                </div>
            </div>
            <p className="mt-[30px] text-[14px] font-medium leading-[16.8px] text-[#264354B2]">
                Учебных материалов с достижениями
            </p>
            <div className="mt-[10px] flex items-center justify-between">
                <div className="flex items-end gap-2">
                    <p className="text-[48px] font-medium leading-[57.6px] text-[#566DA3]">
                        28
                    </p>
                    <DemoButton>
                        <p className="cursor-pointer pb-[13px] text-[14px] font-medium leading-[16.8px] text-[#264354B2]">
                            Смотреть &#8594;
                        </p>
                    </DemoButton>
                </div>
                <DemoButton>
                    <button
                        className="flex h-[35px] items-center rounded-full bg-[#73C3F3CC] px-[18px] py-[5px] text-[14px] font-medium text-[#FFFFFF]"
                    >
                        Достигать &#8594;
                    </button>
                </DemoButton>
            </div>
        </div>
    );
};
