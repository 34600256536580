import * as http from "shared/http";
import useSWR from "swr";
import * as types from "shared/types";
import { useQuery } from "@tanstack/react-query";

export function getUserWeekProgress() {
    return http.request<types.WeekProgress>({
        method: "get",
        url: `/api/v1/user/week_progress`,
    });
}

export default function useUserWeekProgress() {
    const { data, status, error } = useQuery({
        queryKey: ["progress-list"],
        queryFn: getUserWeekProgress,
    });

    return {
        weekProgress: data,
        isLoadingMaterial: status === "pending",
        isError: status === "error",
    };
}