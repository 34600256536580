import { ToastBar, Toaster } from "react-hot-toast";

function CustomToaster() {
    return (
        <Toaster position="bottom-right">
            {(t) => (
                <ToastBar
                    toast={t}
                    style={{
                        ...t.style,
                        animation: t.visible
                            ? "animate-enter"
                            : "animate-leave",
                    }}
                />
            )}
        </Toaster>
    );
}

export default CustomToaster;
