import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";

function startSession(url: string): Promise<types.Content> {
    return http.request<types.Content>({
        method: "get",
        url: url,
    });
}

export default function useSession(id: number | string) {
    const { data, error, mutate } = useSWR(
        `/api/v1/user/content/${id}/start`,
        startSession,
        { revalidateOnFocus: false }
    );

    return {
        data,
        isLoading: !error && !data,
        isError: error,
        mutate: mutate,
    };
}
