import React from "react";

interface Props {
    children: React.ReactNode;
    className: string;
}

const CardNew: React.FC<Props> = ({ children, className = "" }) => {
    return (
        <div
            className={
                "group flex h-[150px] w-full max-w-[1415px] cursor-pointer flex-row justify-between rounded-xl bg-white text-[16px] font-bold text-textblack shadow-[0_0_10px_0_rgba(38,67,84,0.1)] max-[1010px]:h-[115px]" +
                className
            }
        >
            {children}
        </div>
    );
};

export default CardNew;
