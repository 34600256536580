import React from "react";
import "./NavbarSettingsProfile.css";
import checkMark from "../../shared/ui/icon/checkMark.svg";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import useUser from "shared/api/useUser";
import { editProfile } from "shared/api/editProfile";
import { editPassword } from "shared/api/editPassword";

interface FormValues {
    first_name: string | undefined;
    surname: string | undefined;
    last_name: string | undefined;
    email: string | undefined;
    old_password: string;
    new_password: string;
    check: string;
}

interface SettingsProfileProps {
    openProfile: boolean;
    toggleProfile: () => void;
}

export const NavbarSettingsProfile: React.FC<SettingsProfileProps> = ({
    openProfile,
    toggleProfile,
}) => {
    const { user } = useUser();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormValues>();

    const onSubmit = (data: FormValues) => {
        if (data !== undefined || data !== null) {
            if (data.email === "") {
                data.email = user?.email;
            }
            if (data.first_name === "") {
                data.first_name = user?.first_name;
            }
            if (data.surname === "") {
                data.surname = user?.surname;
            }
            if (data.last_name === "") {
                data.last_name = user?.last_name;
            }
            editProfile(
                data.first_name,
                data.surname,
                data.last_name,
                data.email
            );
        }
        if (
            data.new_password !== "" ||
            data.old_password !== "" ||
            data.check !== ""
        ) {
            if (data.new_password === data.check) {
                editPassword(data.old_password, data.new_password);
            } else {
                toast.error("Новые пароли отличаются");
            }
        }
        reset();
    };

    return (
        <>
            <div
                className={`navbarSettings  ${
                    openProfile && "open"
                }`}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="navbarSettingsInfo"
                >
                    <div className="navbarSettingsContainerInfo">
                        <h4 className="navbarSettingsTitle">
                            Настройка профиля
                        </h4>
                        <div className="navbarSettingsContainer">
                            <div className="itemSettigsProfile">
                                <p className="textSettings">Имя</p>
                                <input
                                    className="inputSettings"
                                    placeholder="Введите имя"
                                    id="name"
                                    {...register("first_name")}
                                    type="text"
                                />
                            </div>
                            {errors.first_name && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.first_name.message}
                                </span>
                            )}

                            <div className="itemSettigsProfile">
                                <p className="textSettings">Фамилия</p>
                                <input
                                    className="inputSettings"
                                    placeholder="Введите фамилию"
                                    id="surname"
                                    {...register("surname")}
                                    type="text"
                                />
                            </div>
                            {errors.surname && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.surname.message}
                                </span>
                            )}

                            <div className="itemSettigsProfile">
                                <p className="textSettings">Отчество</p>
                                <input
                                    className="inputSettings"
                                    placeholder="Введите отчество"
                                    id="last_name"
                                    {...register("last_name")}
                                    type="text"
                                />
                            </div>
                            {errors.last_name && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.last_name.message}
                                </span>
                            )}

                            <div className="itemSettigsProfile">
                                <p className="textSettings">
                                    Электронная почта
                                </p>
                                <input
                                    className="inputSettings"
                                    placeholder="Введите email"
                                    id="email"
                                    {...register("email")}
                                    type="email"
                                />
                            </div>
                            {errors.email && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.email.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="navbarSettingsContainerInfo">
                        <h4 className="navbarSettingsTitle">
                            Изменение пароля
                        </h4>
                        <div className="navbarSettingsContainer">
                            <div className="itemSettigsProfile">
                                <p className="textSettings">Старый пароль</p>
                                <input
                                    className="inputSettings"
                                    placeholder="**********"
                                    autoComplete="new-password"
                                    id="old_password"
                                    {...register("old_password")}
                                    type="password"
                                />
                            </div>
                            {errors.old_password && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.old_password.message}
                                </span>
                            )}

                            <div className="itemSettigsProfile">
                                <p className="textSettings">Новый пароль</p>
                                <input
                                    className="inputSettings"
                                    placeholder="**********"
                                    id="new_password"
                                    {...register("new_password")}
                                    type="password"
                                />
                            </div>
                            {errors.new_password && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.new_password.message}
                                </span>
                            )}

                            <div className="itemSettigsProfile">
                                <p className="textSettings">
                                    Подтвердите новый пароль
                                </p>
                                <input
                                    className="inputSettings"
                                    placeholder="**********"
                                    id="check"
                                    {...register("check")}
                                    type="password"
                                />
                            </div>
                            {errors.check && (
                                <span
                                    role="alert"
                                    className="text-sm text-gray-500"
                                >
                                    {errors.check.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="flex h-[38px] w-[215px] items-center justify-between rounded-full bg-blue p-[14px] text-[12px] font-medium text-white"
                    >
                        Сохранить изменения{" "}
                        <img src={checkMark} alt="Картинка" />
                    </button>
                </form>
            </div>
            <div
                className={`backdrop ${openProfile ? "show" : ""}`}
                onClick={toggleProfile}
            ></div>
        </>
    );
};
