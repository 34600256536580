import { useParams, useLocation } from "react-router-dom";
import { TitleLink } from "../../components/TitleLink/TitleLink";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import * as types from "shared/types";
import { MaterialItemLib } from "components/MaterialItem/MaterialItemLib";
import { MaterialItemSmall } from "components/MaterialItem/MaterialItemSmall";
import { Loader } from "components/Loader/Loader";
import { MaterialItemBlocked } from "components/MaterialItem/MaterialItemBlocked";

const ContentModel = CreatePaginatedModel<types.MaterialType>("Content");

function ThreatMaterials() {
    const { skillId } = useParams<{ skillId: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const materials = useStore(ContentModel.store);
    const location = useLocation();
    const skill = location.state.active;
    const [width, setWidth] = useState(window.innerWidth);

    const ModelApi = CreateModelApi(
        ContentModel,
        `/api/v1/user/active_threat/${skillId}/content`,
        "",
        10
    );

    useEffect(() => {
        ModelApi.loadFirstPage().then(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
    }, [width]);

    if (isLoading) return <Loader text="Загрузка..." />;

    return (
        <section className="relative flex h-screen w-full flex-col items-center overflow-y-scroll">
            <TitleLink title="Навык" />
            {materials?.results?.length > 0 ? (
                <ul className="flex h-full w-full flex-col items-center space-y-[30px] overflow-y-scroll pb-[20px]">
                    {materials.results.map((material, key) => (
                        <>
                            {width > 834 ? (
                                skill.is_super === true ? (
                                    <li
                                        className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                        key={key}
                                        children={
                                            <MaterialItemBlocked
                                                material={material}
                                            />
                                        }
                                    />
                                ) : (
                                    <li
                                        className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                        key={key}
                                        children={
                                            <MaterialItemLib
                                                material={material}
                                            />
                                        }
                                    />
                                )
                            ) : (
                                <li
                                    className="mt-[20px] w-full max-w-[1415px] px-[16px]"
                                    key={key}
                                    children={
                                        <MaterialItemSmall
                                            material={material}
                                        />
                                    }
                                />
                            )}
                        </>
                    ))}
                    {materials.next && (
                        <button
                            className="mx-auto my-4 w-fit rounded-full bg-blue px-4 py-2 text-white"
                            onClick={() => ModelApi.appendNextPage(materials)}
                        >
                            Загрузить еще
                        </button>
                    )}
                </ul>
            ) : (
                <p className="flex justify-center text-center text-[25px] font-bold">
                    Ваши учебные профили формируются
                </p>
            )}
        </section>
    );
}

export default ThreatMaterials;
