import useUser from "shared/api/useUser";
import studentPic from "shared/ui/pic/studentPic.png";

export const CardEvent = () => {
    const { user } = useUser();
    return (
        <div className="flex w-full max-w-[695px] flex-col justify-between gap-[49px] rounded-[10px] p-[20px] shadow-[0px_0px_15px_0px_#2643541A]">
            <div className="flex justify-between">
                <div className="flex flex-col gap-[10px]">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#F37D73]">
                        Профиль 1
                    </p>
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#264354]">
                        Видеоконференция
                    </p>
                </div>
                <div
                    className="hidden cursor-pointer max-[834px]:flex"
                >
                    <div className="flex min-w-[25px] flex-col justify-center gap-[3px] max-[834px]:mr-[10px]">
                        {user?.first_name === null && user?.surname === null ? (
                            <p className="text-[12px] font-medium leading-[14.4px] text-[#264354CC]">
                                {user?.email}
                            </p>
                        ) : (
                            <p className="text-[12px] font-medium leading-[14.4px] text-[#264354CC]">
                                {user?.surname} {user?.first_name}
                            </p>
                        )}
                        <p className="text-[12px] font-normal leading-[14.4px] text-[#26435466]">
                            Администратор
                        </p>
                        {/* Должность */}
                    </div>
                    <img
                        src={studentPic}
                        alt="manIcon"
                        className="h-[37px] w-[37px] rounded-full border bg-gray-100"
                    />
                    {/* Иконка пользователя */}
                </div>
            </div>
            <div className="flex flex-col items-center gap-[14px]">
                <p className="text-[20px] font-medium leading-[24px] text-[#264354]">
                    Финансовый менеджемент
                </p>
                <p className="text-[16px] font-medium leading-[19.2px] text-[#F37D73B2]">
                    19 февраля, понедельник
                </p>
                <p className="text-[20px] font-normal leading-[24px] text-[#264354]">
                    18:00
                </p>
            </div>
            <button
                className="flex h-[35px] items-center justify-center rounded-full bg-[#73C3F3CC] px-[18px] py-[5px]"
            >
                <p className="text-[14px] font-medium text-[#FFFFFF] ">
                    Перейти &#8594;
                </p>
            </button>
        </div>
    );
};
