import * as http from "shared/http";
import * as types from "shared/types";
import { Paginated, Achievement } from "shared/types";
import useSWR from "swr";
import { CreateModelApi, CreatePaginatedModel } from "../paginatedModelBuilder";

export function getAchievements() {
    return http.request<Paginated<Achievement[]>>({
        method: "get",
        url: "/api/v1/achievements",
    });
}

export default function useAchievements() {
    const { data, error } = useSWR("getAchievements", getAchievements);

    return {
        achievementsList: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export const AchievementsModel =
    CreatePaginatedModel<types.Achievement>("Achievement");
