import React from "react";
import { useNavigate } from "react-router-dom";

interface TitleProps {
    title: string;
}

export const TitleLink: React.FC<TitleProps> = ({ title }) => {
    const navigate = useNavigate();

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    return (
        <div className="mb-[20px] mt-[30px] flex max-h-[61px] w-full max-w-[1415px] grow items-center justify-between px-[16px] ">
            <div className="flex flex-col">
                <p className="h-[37px] cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                    <span onClick={handleClickLinkBack}>{"\u2190 "}</span>
                    <span onClick={handleClickLinkMain}>Главная</span>
                </p>
                <p className="text-[20px] font-semibold leading-[24px] text-[#264354] max-[834px]:hidden">
                    {title}
                </p>
            </div>
        </div>
    );
};
