import * as http from "shared/http";
import { Paginated, ActiveThreatType } from "shared/types";
import useSWR from "swr";
import { useQuery } from "@tanstack/react-query";

export function getLeftSkillsGoal(id: string) {
    // console.log("cerf")
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: `/api/v1/user/actives_threats?trajectory_active=${id}&has_content_or_quiz=true`,
    });
}

export default function useLeftSkillsGoal(id) {
    const { data, status, error } = useQuery({
        queryKey: ["skills-list", id],
        queryFn: () => getLeftSkillsGoal(id),
        enabled: !(id === undefined || id === null),
    });

    return {
        leftSkillList: data,
        isLoadingSkill: status === "pending",
        isErrorSkill: status === "error",
    };
}
