import * as http from "shared/http";
import toast from "react-hot-toast";

export const editProfile = (
    first_name: string | undefined,
    surname: string | undefined,  
    last_name: string | undefined,  
    email: string | undefined
) => {
    http.request({
        method: "patch",
        url: `/api/v1/profile`,
        data: {
            first_name: first_name,
            surname: surname,
            last_name: last_name,
            email: email,
        },
    })
    .then((res) => {
        toast.success("Данные изменены");
    })
};