import { toast } from "react-hot-toast";
import { ReactComponent as Bell } from "shared/image/icon/Bell.svg";
import { ReactComponent as Shield } from "shared/image/icon/ShieldIcon.svg";
import { ReactComponent as Lightning } from "shared/image/icon/LightningIcon.svg";
import { ReactComponent as Material } from "shared/image/icon/ContentArcticleIcon.svg";

export const infoToast = (content: string) => customToast(Bell, content);
export const shieldToast = (content: string) => customToast(Shield, content);
export const lightningToast = (content: string) =>
    customToast(Lightning, content);
export const materialToast = (content: string) =>
    customToast(Material, content);

export function customToast(
    Icon: React.FC<React.SVGProps<SVGSVGElement>>,
    content: string
) {
    toast.custom((t) => (
        <div
            className={`${
                t.visible ? "animate-enter" : "animate-leave"
            } flex w-fit max-w-md rounded-lg bg-white p-2 shadow-lg`}
        >
            <Icon className="mr-2 h-[24px] w-[24px] shrink-0" />
            <span className="w-fit break-all">{content}</span>
        </div>
    ));
}
