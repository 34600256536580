import ElementNew from "../Card/Card";
import { useState } from "react";
import LineRed from "shared/ui/icon/LineRed.svg";
import LineBlue from "shared/ui/icon/LineBlue.svg";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { ReactComponent as Statistic } from "shared/ui/icon/Statistic.svg";
import Img from "../../shared/ui/icon/RocketIcon.svg";
import { ProgramContent } from "shared/types";

interface Props {
    material: ProgramContent;
}

export const MaterialItem: React.FC<Props> = ({ material }) => {
    const [hovered, sethovered] = useState(false);

    return (
        <div
            onMouseEnter={() => sethovered(true)}
            onMouseLeave={() => sethovered(false)}
        >
            <ElementNew className="">
                <div className="flex h-full w-full min-w-[200px] max-w-[334px] items-center rounded-l-md px-[14px] py-[15px]">
                    <div className="flex flex-col">
                        <p className="flex gap-x-1 text-[14px] font-medium leading-[16.8px] text-[#264354B3]">
                            Навык
                            <span className="text-[#73BDE9]">
                                ({material?.content.name})
                            </span>
                        </p>
                        <p className="flex h-[37px] items-end text-[14px] font-medium text-[#F37D73] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            {material?.content.content_type?.verbose_name}
                        </p>
                        <p className="flex h-[36px] items-end text-[16px] font-semibold text-textblack">
                            Наименование УМа
                        </p>
                    </div>
                </div>
                {hovered === false ? (
                    <img src={LineBlue} alt="lineBlue" />
                ) : (
                    <img src={LineRed} alt="lineRed" />
                )}
                <div className="relative flex w-full max-w-[715px] flex-col px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <div className="mb-[24px] flex min-w-[200px] justify-start text-end max-[1010px]:mb-[12px]">
                        <Statistic />
                        <p className="ml-[5px] mt-[5px] text-sm font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Твой прогресс по учебному материалу
                        </p>
                    </div>
                    <div className="flex">
                        <div className="flex w-full min-w-[100px] max-w-[204px] flex-col">
                            <p className="mb-[38px] text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                                Прогресс изучения материала
                            </p>
                            <ProgressBar
                                percent={
                                    material?.session?.percent_progress || 0
                                }
                                background="#F37D73"
                                color="#F37D73"
                                height="3px"
                                fontSize=""
                                lineHeight=""
                                top=""
                                right=""
                            />
                        </div>
                    </div>
                </div>
                <>
                    {hovered === false ? (
                        <img src={LineBlue} alt="lineBlue" />
                    ) : (
                        <img src={LineRed} alt="lineRed" />
                    )}
                </>
                <div className="flex h-[150px] w-full min-w-[250px] max-w-[348px] flex-col items-start overflow-hidden px-[19px] py-[15.5px] opacity-20 group-hover:opacity-100">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                        Достижения за прохождение
                    </p>
                    <div className="flex">
                        <img
                            className="opacity-[0.7]"
                            src={Img}
                            alt="Картинка"
                        />
                        <div className="mt-[10px] flex flex-col gap-[4px]">
                            <p className="text-[16px] font-medium leading-[19.2px] text-[#F37D73CC]">
                                Буквоед
                            </p>
                            <p className="text-[12px] font-medium leading-[14.4px] text-[#26435499]">
                                Вы прочитали статью 1
                            </p>
                        </div>
                    </div>
                </div>
            </ElementNew>
        </div>
    );
};
