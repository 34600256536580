import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";
import { CreatePaginatedModel } from "shared/paginatedModelBuilder";
import { Paginated, TrajectoryActiveType } from "shared/types";

export function getActiveDeps(url: string) {
    return http.request<Paginated<TrajectoryActiveType>>({
        method: "get",
        url: url,
    });
}

export default function useActiveDeps(id: number | string) {
    const { data, error } = useSWR(
        `/api/v1/user/trajectory_actives/${id}/dependencies`,
        getActiveDeps
    );

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export const TrajectoryActiveTypeModel = CreatePaginatedModel<types.TrajectoryActiveType>("TrajectoryActiveType")