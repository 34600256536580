import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { useStore } from "effector-react";
import * as types from "shared/types";

import * as http from "shared/http";

import {
    ActiveThreatType,
    TrajectoryActiveType,
    MaterialType,
} from "shared/types";
import {
    CreateModelApi,
    CreatePaginatedModel,
} from "shared/paginatedModelBuilder";
import { ActiveCard } from "./ActiveCard";
import { ThreatCard } from "./ThreatCard";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";

// const TrajectoryActivesModel =
//     CreatePaginatedModel<types.TrajectoryActiveType>("TrajectoryActives");
// const ActiveThreatsModel =
//     CreatePaginatedModel<types.ActiveThreatType>("ActiveThreats");
const SkillsContentModel =
    CreatePaginatedModel<types.MaterialType>("SkillsContent");

export const Timeline: React.FC = () => {
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    const [openThreats, setOpenThreats] = useState(false);
    const [openMaterials, setOpenMaterials] = useState(false);

    // const location = useLocation();
    // const goal = location.state.leftActive;

    const content = useStore(SkillsContentModel.store); // Получение модели умов навыка
    const contentList = content.results; // Получение результатов модели

    const {
        data: activesList,
        error: activesError,
        status: activesStatus,
    } = useQuery({
        queryKey: ["user_trajectory_actives", "threats_count__gt=0"],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.TrajectoryActiveType>
            >({
                url: `/api/v1/user/trajectory_actives?non_empty_threats_count__gt=0&page_size=200`,
            });
            return response.results;
        },
    });

    const [selectedActiveId, setSelectedActiveId] = useState<
        number | undefined
    >(undefined);
    if (selectedActiveId === undefined && activesList?.length > 0) {
        setSelectedActiveId(activesList[0].id);
    }

    const selectedActive = activesList?.find((a) => a.id == selectedActiveId);

    const {
        data: threatsData,
        error: threatsError,
        status: threatsStatus,
    } = useQuery({
        queryKey: [
            "user_actives_threats",
            "has_content_or_quiz=true",
            selectedActiveId,
        ],
        queryFn: async () => {
            const response = await http.request<
                types.Paginated<types.ActiveThreatType>
            >({
                url: `/api/v1/user/actives_threats?trajectory_active=${selectedActiveId}&has_content_or_quiz=true`,
            });
            return response.results;
        },
        enabled: selectedActiveId != undefined,
    });
    const threatsList = threatsData ?? [];
    const [selectedThreatId, setSelectedThreatId] = useState<
        number | undefined
    >(undefined);
    const selectedThreat = threatsList?.at(selectedThreatId);

    const [editMaterial, setEditMaterial] = useState<MaterialType>();
    const [openedThreat, setOpenedThreat] = useState("");

    // const ModelApi3 = CreateModelApi(
    //     SkillsContentModel,
    //     `/api/v1/user/active_threat/${editThreat?.id}/content`,
    //     "",
    //     150
    // );

    const threatToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    for (const [i, active_threat] of threatsList.entries()) {
        if (i === 0) {
            threatToCompletion[active_threat.id] = {
                isAvailable: true,
                prev: null,
            };
        } else {
            const prevThreat = threatsList[i - 1];
            const nextElem = { isAvailable: false, prev: prevThreat.id };
            const prevAvailable = threatToCompletion[prevThreat.id].isAvailable;
            const prevThreatCompletion =
                prevThreat.content_count - prevThreat.completed_content_count;
            if (prevAvailable && prevThreatCompletion === 0) {
                nextElem.isAvailable = true;
            }

            threatToCompletion[active_threat.id] = nextElem;
        }
    }

    // Старое
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);

    const handleClickLinkGoal = (evt) => {
        evt.stopPropagation();
        navigate("/goals");
    };

    const handleClickLinkMain = (evt) => {
        evt.stopPropagation();
        navigate("/");
    };

    const handleClickLinkBack = (evt) => {
        evt.stopPropagation();
        navigate(-1);
    };

    const scrollLeft = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(-250, 0);
    };

    const scrollRight = () => {
        const ul = document.querySelector(".container-scroll");
        ul.scrollBy(250, 0);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });
        // if (isLoadingContent) {
        //     ModelApi3.loadFirstPage().then(() => {
        //         setIsLoadingContent(false);
        //     });
        // }
    }, [width, isLoadingContent]);
    console.log(activesList);

    return (
        <main className="flex w-full flex-col overflow-auto">
            <section className="relative flex w-full justify-center">
                <div className="mb-[10px] mt-[30px] flex w-full max-w-[1415px] justify-between max-[834px]:mb-[60px]">
                    <div className="flex h-screen w-full flex-col">
                        <div className="mb-[45px] flex flex-col gap-[19px] max-[834px]:mb-[40px]">
                            <p className="cursor-pointer text-[14px] font-normal leading-[16.8px] text-[#2643544D]">
                                <span onClick={handleClickLinkBack}>
                                    &#8592;
                                </span>{" "}
                                <span onClick={handleClickLinkMain}>
                                    Главная
                                </span>{" "}
                                <span onClick={handleClickLinkGoal}>/Цели</span>
                            </p>
                            <h2 className="text-[20px] font-bold leading-[24px] text-[#264354]">
                                {/* {goal?.active?.name} */}
                            </h2>
                        </div>
                        {activesStatus === "success" &&
                        threatsStatus === "success" ? (
                            <div className="flex w-full gap-x-[30px]">
                                <div className="flex flex-col gap-y-[40px]">
                                    <p className="text-tr-l font-medium text-main-dark">
                                        Все цели
                                    </p>
                                    <div className="flex flex-col">
                                        {activesList !== undefined && (
                                            <>
                                                {activesList?.map(
                                                    (active, index) => {
                                                        return (
                                                            <ActiveCard
                                                                active={active}
                                                                selectedActiveId={
                                                                    selectedActiveId
                                                                }
                                                                setSelectedActiveId={
                                                                    setSelectedActiveId
                                                                }
                                                                setOpenMaterials={
                                                                    setOpenMaterials
                                                                }
                                                                setOpenThreats={
                                                                    setOpenThreats
                                                                }
                                                                index={index}
                                                                activeListLenght={
                                                                    activesList.length
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex w-full flex-col gap-y-[40px]">
                                    <div className="flex flex-col gap-y-[10px]">
                                        <p className="flex gap-x-[5px] text-tr-l font-medium text-[#369F48]">
                                            Навыки в цели
                                        </p>
                                        <p className="text-tr-l font-medium text-main-dark">
                                            {selectedActive.active.name}
                                        </p>
                                    </div>

                                    <div className="mb-[20px] flex w-full flex-col gap-y-[20px]">
                                        {threatsList !== undefined && (
                                            <>
                                                {threatsList?.map(
                                                    (skill, index) => {
                                                        return (
                                                            <ThreatCard
                                                                threat={skill}
                                                                setEditMaterial={
                                                                    setEditMaterial
                                                                }
                                                                setSelectedThreatId={
                                                                    setSelectedThreatId
                                                                }
                                                                setOpenedThreat={
                                                                    setOpenedThreat
                                                                }
                                                                selectedThreatId={
                                                                    selectedThreatId
                                                                }
                                                                openedThreat={
                                                                    openedThreat
                                                                }
                                                                index={index}
                                                                activeOrdered={
                                                                    selectedActive.is_ordered
                                                                }
                                                                threatListLength={
                                                                    threatsList.length
                                                                }
                                                                available={
                                                                    threatToCompletion[
                                                                        skill.id
                                                                    ]
                                                                        .isAvailable
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex h-full w-full">
                                <Loader text="Загружаю" />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Timeline;
