import ProgressCircle from "shared/ui/ProgressCircle";

function Spinner() {
    return (
        <div className="flex grow flex-col items-center justify-center bg-white">
            <ProgressCircle
                progress={0.25}
                width={20}
                className="h-[80px] animate-spin"
            />
            <p className="text-xl">Загрузка</p>
        </div>
    );
}

export default Spinner;
