import { toast } from "react-hot-toast";
import * as http from "shared/http";

function Counter({ startTime, mutate, materialId }) {
    function handleMeetingStart() {
        http.request({
            method: "get",
            url: `/api/v1/meeting/${materialId}/start`,
        })
            .then(() => mutate())
            .then(() => toast.success("Успешно"));
    }

    const currentTimestamp = Date.now();
    const startTimestamp = new Date(startTime).getTime();
    const delta = startTimestamp - currentTimestamp;
    const remainingTime = new Date(delta < 0 ? 0 : delta);

    const shouldBeRunning = currentTimestamp > startTimestamp;

    function addZero(arg: number) {
        return ("0" + arg).slice(-2);
    }

    if (shouldBeRunning) {
        mutate();
    }

    return (
        <section className="mb-[35px] flex grow flex-col items-center justify-center">
            <div className="flex grow flex-col justify-center">
                <p className="w-fit text-[64px] font-bold text-black">
                    До начала осталось
                </p>
                <div className="mt-[15px] flex w-fit">
                    <div className="flex-col">
                        <p className="text-[96px] font-medium text-blue">--</p>
                        <p className="mt-[-10px] text-center text-ext-2xl font-medium text-grey7">
                            Дней
                        </p>
                    </div>

                    <div className="ml-[30px] w-[160px] flex-col">
                        <p className="border-l-[4px] border-solid border-grey3 pl-[30px] text-[96px] font-medium text-blue">
                            {addZero(remainingTime.getUTCHours())}
                        </p>
                        <p className="mt-[-10px] pl-[30px] text-center text-ext-2xl font-medium text-grey7">
                            Часов
                        </p>
                    </div>

                    <div className="ml-[30px] w-[160px] flex-col">
                        <p className="border-l-[4px] border-solid border-grey3 pl-[30px] text-[96px] font-medium text-blue">
                            {addZero(remainingTime.getUTCMinutes())}
                        </p>
                        <p className="mt-[-10px] pl-[30px] text-center text-ext-2xl font-medium text-grey7">
                            Минут
                        </p>
                    </div>

                    <div className="ml-[30px] w-[160px] flex-col">
                        <p className="border-l-[4px] border-solid border-grey3 pl-[30px] text-[96px] font-medium text-blue">
                            {addZero(remainingTime.getUTCSeconds())}
                        </p>
                        <p className="mt-[-10px] pl-[30px] text-center text-ext-2xl font-medium text-grey7">
                            Секунд
                        </p>
                    </div>
                </div>
            </div>
            <button
                className="mx-auto mt-2 w-fit rounded-md bg-blue px-4 py-2 text-white"
                onClick={handleMeetingStart}
            >
                Запустить преждевременно
            </button>
        </section>
    );
}

export default Counter;
