import React, { useState } from "react";
import "./NavbarCalendar.css";
import { Calendar } from "components/Calendar/Calendar";
import { CardEvent } from "components/CardEvent/CardEvent";
import ArrowDown from "shared/ui/icon/ArrowDown.svg";

interface NavbarNotificationProps {
    openCalendar: boolean;
    toggleCalendar: () => void;
}

export const NavbarCalendar: React.FC<NavbarNotificationProps> = ({
    openCalendar,
    toggleCalendar,
}) => {
    const [openEvents, setOpenEvents] = useState(false);
    const date = new Date();
    const dayOfWeek = date.toLocaleDateString("ru-RU", { weekday: "long" });
    const month = date.toLocaleDateString("ru-RU", {
        day: "numeric",
        month: "long",
    });

    return (
        <>
            <div className={`navbarCalendar ${openCalendar ? "open" : ""}`}>
                <div className="navbarCalendarInfo">
                    <div className="navbarCalendarContainerInfo">
                        <div className="flex flex-col items-center gap-[6px]">
                            <p className="text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                                Сегодня
                            </p>
                            <p className="text-[20px] font-medium leading-[24px] text-[#264354]">
                                {`${month}, ${dayOfWeek}`}
                            </p>
                        </div>
                        <Calendar />
                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <p className="text-[16px] font-medium leading-[19.2px] text-[#264354]">
                            События
                        </p>
                        <div className="flex gap-[47px]">
                            <p className="text-[14px] font-normal leading-[16.8px] text-[#264354] underline">
                                Текущие события
                            </p>
                            {!openEvents ? (
                                <img
                                    src={ArrowDown}
                                    alt="arrowDown"
                                    className="rotate-[270deg] cursor-pointer"
                                    onClick={() => setOpenEvents(true)}
                                />
                            ) : (
                                <img
                                    src={ArrowDown}
                                    alt="arrowDown"
                                    className="cursor-pointer"
                                    onClick={() => setOpenEvents(false)}
                                />
                            )}
                        </div>
                    </div>
                    {openEvents ? <CardEvent /> : ""}
                </div>
            </div>
            <div
                className={`backdrop ${openCalendar ? "show" : ""}`}
                onClick={toggleCalendar}
            ></div>
        </>
    );
};
