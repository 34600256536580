import React from 'react'
import LoaderRed from "shared/ui/icon/LoaderRed.svg";
import LoaderBlue from "shared/ui/icon/LoaderBlue.svg";
import './Loader.css'

interface Loaders {
  text: string
}

export const Loader: React.FC<Loaders> = ({text}) => {
  return (
    <div className='containerLoader'>
        <img src={LoaderBlue} alt="LoaderBlue" className='loaderBlue' />
        <img src={LoaderRed} alt="LoaderRed" className='loaderRed' />
        <p className='textLoader'>{text}</p>
    </div>
  )
}
