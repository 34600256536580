import * as http from "shared/http";
import { Paginated, ActiveThreatType } from "shared/types";
import useSWR from "swr";

export function getSkills() {
    return http.request<Paginated<ActiveThreatType>>({
        method: "get",
        url: "/api/v1/user/actives_threats",
    });
}

export default function useSkills() {
    const { data, error } = useSWR("getSkills", getSkills);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    };
}