import React from "react";
import { useNavigate } from "react-router-dom";
import "./NavbarMenu.css";

interface MenuItemType {
    link: string;
    text: string;
    logo: React.ReactElement;
}

interface NavbarMenuProps {
    openMenu: boolean;
    toggleMenu: () => void;
}

const MenuItem: MenuItemType[] = [
    {
        link: "",
        text: "Цели",
        logo: <div className="yourImageTarget"></div>,
    },
    {
        link: "materials",
        text: "Учебные материалы",
        logo: <div className="yourImageMat"></div>,
    },
];

export const NavbarMenu: React.FC<NavbarMenuProps> = ({
    openMenu,
    toggleMenu,
}) => {
    const navigate = useNavigate();

    const clickMenuItem = (link: string) => {
        navigate(`/${link}`);
        toggleMenu();
    };

    return (
        <>
            <nav className={`navbar ${openMenu ? "open" : ""}`}>
                <div className="navbarMenuInfo">
                    <ul className="ulMenu">
                        {MenuItem.map((item: MenuItemType, key: number) => (
                            <li
                                className="liMenu"
                                key={key}
                                onClick={() => clickMenuItem(item.link)}
                            >
                                <div className="group inline h-[70px] w-[230px] cursor-pointer">
                                    <div className="flex">
                                        <div className="mr-[15px] flex h-[70px] w-[80px] items-center justify-center rounded-md bg-white shadow-[0px_7px_22px_0px_#2643541A]">
                                            {item.logo}
                                        </div>
                                        <div className="my-auto flex-col">
                                            <p className="textMenu">
                                                {item.text}
                                            </p>
                                            <p className="linkMenu">
                                                Перейти &rarr;
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            <div
                className={`backdrop ${openMenu ? "show" : ""}`}
                onClick={toggleMenu}
            ></div>
        </>
    );
};
