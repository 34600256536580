import { Calendar } from "components/Calendar/Calendar";
import { Events } from "components/Calendar/Events";
import { Loader } from "components/Loader/Loader";
import { useSessionPopup } from "components/MaterialSessionPopup/model";
import { ProgressBar } from "components/ProgressBar/ProgressBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useGoalsList, { getGoalsList } from "shared/api/useGoalsList";
import { useIncomplete } from "shared/api/useIncomplete";
import useLeftSkillsGoal from "shared/api/useLeftSkillsGoal";
import useMaterialsSkill from "shared/api/useMaterialsSkill";
import useSkillsGoal from "shared/api/useSkillsGoal";
import useUser from "shared/api/useUser";
import useUserWeekProgress from "shared/api/useUserWeekProgress";
import {
    ActiveThreatType,
    MaterialType,
    TrajectoryActiveType,
} from "shared/types";
import ProgressCircle from "shared/ui/ProgressCircle";
import { ReactComponent as AchieveLoad } from "../../shared/ui/icon/achieveLoad.svg";
import { ReactComponent as EmptyAchievements } from "../../shared/ui/icon/EmptyAchievements.svg";
import { ReactComponent as ListClose } from "../../shared/ui/icon/ListClose.svg";
import { ReactComponent as ListOpen } from "../../shared/ui/icon/ListOpen.svg";
import "./index.css";
import { useQuery } from "@tanstack/react-query";
import { PopupTest } from "components/PopupTest/PopupTest";
import useThreatTest from "shared/api/useThreatTest";
import * as types from "shared/types";
import { LoaderConstructor } from "./LoaderConstructor/LoaderConstructor";

function TestCard({
    editThreat,
    isQuizAvailable,
    setOpenPopupTest,
}: {
    editThreat: types.ActiveThreatType | undefined;
    isQuizAvailable: boolean;
    setOpenPopupTest: (boolean) => void;
}) {
    // const { quiz } = useThreatTest(String(editThreat?.quiz));

    function checkOpen() {
        if (isQuizAvailable) {
            setOpenPopupTest(true);
        }
    }

    // if (quiz === undefined) return <LoaderConstructor />;
    return (
        <div
            onClick={checkOpen}
            className={`group flex h-full max-h-[60px] w-full max-w-[295px] cursor-pointer justify-between p-[10px] ${
                isQuizAvailable === true
                    ? " hover:bg-blue"
                    : "  hover:bg-gray-500"
            }  hover:bg-opacity-20`}
        >
            <div className="flex w-full flex-col gap-y-[5px]">
                <p
                    className={`text-tr-xs font-normal ${
                        isQuizAvailable === true
                            ? " text-[#566DA3]"
                            : "  text-gray-500"
                    }  `}
                >
                    Опросник
                </p>
                <p
                    className={`text-tr-s font-medium ${
                        isQuizAvailable === true
                            ? "text-[#26435499]"
                            : "text-gray-500"
                    }  underline`}
                >
                    {editThreat?.quiz.name}
                </p>
            </div>
            {isQuizAvailable === false ? (
                <svg
                    className="opacity-0 group-hover:opacity-100"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_11119_184352)">
                        <path
                            d="M3 5V4C3 3.82964 3.0142 3.6626 3.04148 3.5M9 5V4C9 2.34315 7.65685 1 6 1C5.10398 1 4.29971 1.39282 3.75 2.01563"
                            stroke="#F37D73"
                            stroke-width="0.75"
                            stroke-linecap="round"
                        />
                        <path
                            d="M5.5 11H4C2.58579 11 1.87868 11 1.43934 10.5607C1 10.1213 1 9.41421 1 8C1 6.58579 1 5.87868 1.43934 5.43934C1.87868 5 2.58579 5 4 5H8C9.41421 5 10.1213 5 10.5607 5.43934C11 5.87868 11 6.58579 11 8C11 9.41421 11 10.1213 10.5607 10.5607C10.1213 11 9.41421 11 8 11H7.5"
                            stroke="#F37D73"
                            stroke-width="0.75"
                            stroke-linecap="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_11119_184352">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <div className="opacity-0 group-hover:opacity-100">&#8594;</div>
            )}
        </div>
    );
}

function MaterialElem({
    material,
    threat,
    available,
    blocked,
}: {
    material: MaterialType;
    threat: ActiveThreatType;
    available: boolean;
    blocked: boolean | undefined;
}) {
    if (threat.is_ordered === false) {
        available = true;
    }

    if (blocked === false) {
        available = false;
    }

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number, materialId: number) => {
        if (available === true) {
            if (typeof threatId !== "number") {
                throw new Error("invalid threatId");
            }
            openActiveMaterial(null, threatId, materialId);
        }
    };

    return (
        <div
            onClick={() => handleClickLinkThreat(threat.id, material.id)}
            className={`group flex h-full max-h-[60px] w-full max-w-[295px] cursor-pointer justify-between p-[10px] ${
                available === true ? " hover:bg-blue" : "  hover:bg-gray-500"
            }  hover:bg-opacity-20`}
        >
            <div className="flex w-full flex-col gap-y-[5px]">
                <p
                    className={`text-tr-xs font-normal ${
                        available === true
                            ? " text-[#566DA3]"
                            : "  text-gray-500"
                    }  `}
                >
                    {material.content_type.verbose_name}
                </p>
                <p
                    className={`text-tr-s font-medium ${
                        available === true
                            ? "text-[#26435499]"
                            : "text-gray-500"
                    }  underline`}
                >
                    {material.name}
                </p>
            </div>
            {available === false ? (
                <svg
                    className="opacity-0 group-hover:opacity-100"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_11119_184352)">
                        <path
                            d="M3 5V4C3 3.82964 3.0142 3.6626 3.04148 3.5M9 5V4C9 2.34315 7.65685 1 6 1C5.10398 1 4.29971 1.39282 3.75 2.01563"
                            stroke="#F37D73"
                            stroke-width="0.75"
                            stroke-linecap="round"
                        />
                        <path
                            d="M5.5 11H4C2.58579 11 1.87868 11 1.43934 10.5607C1 10.1213 1 9.41421 1 8C1 6.58579 1 5.87868 1.43934 5.43934C1.87868 5 2.58579 5 4 5H8C9.41421 5 10.1213 5 10.5607 5.43934C11 5.87868 11 6.58579 11 8C11 9.41421 11 10.1213 10.5607 10.5607C10.1213 11 9.41421 11 8 11H7.5"
                            stroke="#F37D73"
                            stroke-width="0.75"
                            stroke-linecap="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_11119_184352">
                            <rect width="12" height="12" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <div className="opacity-0 group-hover:opacity-100">&#8594;</div>
            )}
        </div>
    );
}

function ActiveInfoSkeleton() {
    return (
        <div className="flex h-full max-h-[665px] w-full max-w-[335px] flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
            <p className="text-tr-m font-medium text-main-dark opacity-40">
                Ваш прогресс по целям
            </p>
            <div className="mt-[20px] flex w-full items-center justify-between">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[117px] w-[117px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mt-[30px] flex w-full items-center justify-between ">
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListOpen className="rotate-90 opacity-30" />
                </div>
                <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full border-[8px] border-[#D6D6D6] text-[#3F4A50]"></div>
                <div className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full">
                    <ListClose className="rotate-90 opacity-30" />
                </div>
            </div>

            <div className="mt-[10px] flex h-[8px] w-full max-w-[135px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>

            <div className="mb-[130px] mt-[30px] flex w-full flex-col items-center gap-y-[10px]">
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
                <div className="flex w-full flex-col">
                    <div className="mt-[10px] flex h-[8px] w-full max-w-[95px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                    <div className="mt-[10px] flex h-[10px] w-full max-w-[285px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                </div>
            </div>
        </div>
    );
}

function ActiveInfoEmptySkeleton() {
    return (
        <div className="flex h-full max-h-[665px] w-full max-w-[335px] flex-col items-center rounded-xl px-[30px] py-[25px] shadow-[0px_7px_22px_0px_#2643541A]">
            <p className="text-tr-m font-medium text-main-dark">
                Ваш прогресс по целям
            </p>
            <div className="mt-[20px] flex w-full items-center justify-center">
                <ProgressCircle
                    className="h-[115px] w-[115px]"
                    colorLine="#ADADAD"
                    colorText="white"
                    ringType="big"
                    progress={0.35}
                />
            </div>
            <p className="mt-[10px] text-tr-s font-medium text-main-dark underline">
                Скоро тут будет цель
            </p>
            <div className="mt-[30px] flex w-full items-center justify-center ">
                <ProgressCircle
                    className="h-[80px] w-[80px]"
                    colorLine="#ADADAD"
                    colorText="white"
                    ringType="big"
                    progress={0.35}
                />
            </div>
            <p className="mt-[10px] text-tr-s font-medium text-main-dark underline">
                Скоро тут будет навыки
            </p>

            <div className="flex h-[300px] w-[295px] flex-col items-center justify-center gap-y-[20px]">
                <svg
                    width="43"
                    height="38"
                    viewBox="0 0 43 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.76562 8.38492L17.0859 2.17639C19.9147 0.857869 23.0849 0.857871 25.9138 2.17639L39.2342 8.38501C42.1317 9.73551 42.1317 14.4104 39.2342 15.7609L25.914 21.9694C23.0851 23.288 19.9149 23.288 17.0861 21.9694L7.56475 17.5316"
                        stroke="#ADADAD"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M2.58789 24.9382V19.273C2.58789 16.7412 2.58789 15.4753 3.24547 14.4838C3.90304 13.4924 5.07339 12.9937 7.41409 11.9964L9.55548 11.084"
                        stroke="#ADADAD"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M21.5016 36.8118C17.9262 36.8118 13.2474 34.5927 10.3242 32.9833C8.56875 32.0167 7.56641 30.1489 7.56641 28.1538V18.0098M35.4368 18.0098V28.1538C35.4368 30.1489 34.4344 32.0167 32.6789 32.9833C31.623 33.5646 30.3381 34.2255 28.9588 34.8327"
                        stroke="#ADADAD"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                </svg>
                <p className="flex w-full max-w-[140px] text-center text-tr-xs font-medium text-[#ADADAD]">
                    Скоро здесь будут учебные материалы
                </p>
            </div>
        </div>
    );
}

function MainGoalSkeleton() {
    return (
        <div className="flex h-full w-full max-w-[1415px] gap-x-[25px]">
            <div className="flex h-full w-full max-w-[1415px] gap-x-[25px] ">
                <div
                    className={`z-50 flex h-full max-h-[665px] w-full max-w-[575px] flex-col rounded-xl bg-[#3F4A50] px-[20px] py-[25px]`}
                >
                    <div className="flex w-full items-start justify-between">
                        <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                            <p className="text-tr-s font-medium text-[#D6D6D6]">
                                Вы уже начали достигать цель
                            </p>
                            <div className="flex h-[20px] w-full max-w-[340px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                        <div className="flex h-[144px] w-[144px] items-center justify-center rounded-full border-[10px] border-[#D6D6D6] text-[#3F4A50]">
                            f
                        </div>
                    </div>
                    <div className="mt-[25px] flex h-full flex-col gap-y-[20px]">
                        <div className="flex w-full max-w-[420px] flex-col gap-y-[40px]">
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                            <div className="flex h-[16px] w-full max-w-[430px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                                f
                            </div>
                        </div>
                    </div>
                    <div
                        className={` mt-[20px] flex w-full items-center justify-between `}
                    >
                        <div className="flex h-[16px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]">
                            f
                        </div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-white text-[30px] text-[#264354B2] opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex h-full max-h-[665px] w-full max-w-[455px] flex-col gap-y-[20px] rounded-xl">
                <div className="flex h-full max-h-[190px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <div className="flex w-full justify-between opacity-40">
                        <p className="text-tr-s font-medium text-[#264354]">
                            Средняя эффективность
                        </p>
                        <div className="flex items-center gap-x-[10px]">
                            <p className="text-tr-s font-normal text-[#264354]">
                                Неделя
                            </p>
                            <ListOpen className="mb-[2px]" />
                        </div>
                    </div>
                    <div className="mt-[25px] flex items-end gap-x-[28px]">
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[66px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[58px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[64px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[20px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[78px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                        <div className="flex flex-col items-center gap-y-[5px]">
                            <div className="h-[82px] w-[35px] rounded bg-[#D6D6D6]"></div>
                        </div>
                    </div>
                </div>

                <div className="flex h-full max-h-[455px] w-full max-w-[455px] flex-col rounded-xl p-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                    <p className="text-tr-s font-semibold text-main-dark opacity-40">
                        Ваши достижения
                    </p>
                    <div className="mt-[20px] grid grid-cols-2 gap-x-2 gap-y-2 overflow-y-auto">
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer  flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                        <div className="group flex h-[145px] w-full cursor-pointer flex-col items-center justify-center gap-y-[20px] rounded border">
                            <AchieveLoad />
                            <div className="flex h-[6px] w-full max-w-[140px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        </div>
                    </div>
                    <div className="mt-[20px] flex w-full items-center justify-between">
                        <div className="flex h-[12px] w-full max-w-[195px] items-center justify-center rounded-full bg-[#D6D6D6] text-[#D6D6D6]"></div>
                        <div className="flex h-[55px] w-[55px] rotate-45 cursor-pointer items-center justify-center rounded-full bg-[#264354B2] text-[30px] text-white opacity-20">
                            &#8594;
                        </div>
                    </div>
                </div>
            </div>

            <ActiveInfoSkeleton />
        </div>
    );
}

function LeftSkill({ skill, activeOrdered, available }) {
    let threatProgress = 0;
    if (skill?.quiz_result_score > 0) {
        threatProgress = skill?.quiz_result_score;
    } else if (skill?.completed_content_count > 0) {
        threatProgress = skill?.completed_content_count / skill?.content_count;
    }

    const skillPercent = (threatProgress * 100).toFixed(0);
    // const skillStat = (
    //     skill?.completed_content_count / skill?.content_count || 0
    // ).toPrecision(1);
    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLinkThreat = (threatId: number) => {
        if (available === true) {
            if (typeof threatId !== "number") {
                throw new Error("invalid threatId");
            }
            openActiveMaterial(null, threatId, null);
        }
    };

    if (activeOrdered === false) {
        available = true;
    }

    return (
        <div className="group flex w-full max-w-[420px] flex-col gap-y-[10px] opacity-50 hover:opacity-100">
            <div
                className="flex w-full cursor-pointer items-center justify-between"
                onClick={() => handleClickLinkThreat(skill.id)}
            >
                <div className="flex items-center gap-x-[5px]">
                    {available === false && (
                        <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_11492_18849)">
                                <path
                                    d="M4.75 7.91634V6.33301C4.75 6.06328 4.77248 5.7988 4.81568 5.54134M14.25 7.91634V6.33301C14.25 3.70966 12.1234 1.58301 9.5 1.58301C8.0813 1.58301 6.80787 2.20497 5.9375 3.19109"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M8.70898 17.417H6.33398C4.09481 17.417 2.97523 17.417 2.27961 16.7214C1.58398 16.0257 1.58398 14.9062 1.58398 12.667C1.58398 10.4278 1.58398 9.30823 2.27961 8.61261C2.97523 7.91699 4.09481 7.91699 6.33398 7.91699H12.6673C14.9065 7.91699 16.0261 7.91699 16.7217 8.61261C17.4173 9.30823 17.4173 10.4278 17.4173 12.667C17.4173 14.9062 17.4173 16.0257 16.7217 16.7214C16.0261 17.417 14.9065 17.417 12.6673 17.417H11.8757"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_11492_18849">
                                    <rect width="19" height="19" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                    <p className="text-tr-m font-medium text-[#FFFFFF]">
                        {skill?.threat.name}
                    </p>
                </div>
                <div className="flex gap-x-[5px]">
                    <p className="text-[#369F48]">{skillPercent} %</p>
                    <p className="hidden -rotate-90 text-white group-hover:block">
                        &#9660;
                    </p>
                </div>
            </div>
            <ProgressBar
                background="#369F48"
                color="#369F48"
                height="3px"
                fontSize=""
                lineHeight=""
                top=""
                right=""
                percent={threatProgress}
            />
        </div>
    );
}

function DayProgress({ day, index }) {
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const dayStat = (
        day.completed_materials / day.opened_materials || 0
    ).toPrecision(1);

    const dayStatString = String(Number(dayStat) * 100);

    return (
        <div
            key={index}
            className="group flex cursor-pointer flex-col items-center justify-end gap-y-[3px]"
        >
            <div className="flex h-[18px] w-[35px] items-center justify-center rounded bg-[#264354] opacity-0 group-hover:opacity-100 ">
                <p className="text-tr-xs font-medium text-white">
                    {Number(dayStat) * 100}%
                </p>
            </div>
            <div
                className={`h-[${dayStatString}px] w-[35px] rounded bg-[#8591AD] group-hover:bg-[#264354]`}
            ></div>
            <p className="text-tr-s font-medium text-[#264354]">
                {daysOfWeek[index]}
            </p>
        </div>
    );
}

function WeekProgress({ weekData }) {
    // Define an array of day names for easier mapping

    const weekData2 = [
        {
            day: "2024-09-02",
            opened_materials: 2,
            completed_materials: 1,
        },
        {
            day: "2024-09-03",
            opened_materials: 3,
            completed_materials: 1,
        },
        {
            day: "2024-09-04",
            opened_materials: 4,
            completed_materials: 1,
        },
        {
            day: "2024-09-05",
            opened_materials: 1,
            completed_materials: 1,
        },
        {
            day: "2024-09-06",
            opened_materials: 5,
            completed_materials: 1,
        },
        {
            day: "2024-09-07",
            opened_materials: 8,
            completed_materials: 2,
        },
        {
            day: "2024-09-08",
            opened_materials: 6,
            completed_materials: 4,
        },
    ];

    return (
        <div className="mt-[10px] flex h-[140px] items-end justify-between">
            {weekData.map((day, index) => (
                <DayProgress day={day} index={index} />
            ))}
        </div>
    );
}

function transformSkills(
    skills: ActiveThreatType[],
    active: TrajectoryActiveType
) {
    const threatToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};
    if (skills !== undefined && active?.is_ordered === true) {
        for (const [i, active_threat] of skills.entries()) {
            if (i === 0) {
                threatToCompletion[active_threat.id] = {
                    isAvailable: true,
                    prev: null,
                };
            } else {
                const prevThreat = skills[i - 1];
                const nextElem = { isAvailable: false, prev: prevThreat.id };
                const prevAvailable =
                    threatToCompletion[prevThreat.id].isAvailable;
                const prevThreatCompletion =
                    prevThreat.content_count -
                    prevThreat.completed_content_count;
                if (prevAvailable && prevThreatCompletion === 0) {
                    nextElem.isAvailable = true;
                }

                threatToCompletion[active_threat.id] = nextElem;
            }
        }
    }

    return threatToCompletion;
}

function MainPage() {
    const { user, isLoading: isUserLoading, isError: isUserError } = useUser();
    // const { userData, isLoading, isError } = useUserInfo();
    // const activeAchievementsList = useIncomplete();

    const { goalsList, isLoadingGoal, isErrorGoal } = useGoalsList();

    const LeftActiveGoalList = getData(goalsList?.results);

    const [loadPage, setLoadPage] = useState(true);
    const [loadActiveInfo, setLoadActiveInfo] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);
    const [threatIndex, setThreatIndex] = useState(0);

    const navigate = useNavigate();
    const [openList, setOpenList] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [leftActive, setLeftActive] = useState<TrajectoryActiveType>();
    const [openCard, setOpenCard] = useState(false);
    const daysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
    const [openPopupTest, setOpenPopupTest] = useState(false);

    const activeGoalList2 =
        goalsList?.results.filter((m) => m.id !== LeftActiveGoalList.id) || [];

    const slideActiveLeft = () => {
        if (activeGoalList2.length > 1 && activeIndex !== 0) {
            setThreatIndex(0);
        }
        setActiveIndex((prev) => Math.max(0, prev - 1));
    };

    const slideActiveRight = () => {
        if (
            activeGoalList2.length > 1 &&
            activeIndex + 1 !== activeGoalList2.length
        ) {
            setThreatIndex(0);
        }
        setActiveIndex((prev) =>
            Math.min(activeGoalList2.length - 1, prev + 1)
        );
    };

    const active = activeGoalList2[activeIndex];

    const { skillList, isLoadingSkill, isErrorSkill } = useSkillsGoal(
        active?.id
    );

    function getData(activeGoalList: TrajectoryActiveType[]) {
        if (activeGoalList?.length > 0) {
            const filteredList = activeGoalList?.filter(
                (item) =>
                    item.completed_threats_count / item.threats_count !== 1
            );
            if (filteredList?.length === 0) {
                const LeftActiveGoalList = activeGoalList?.reduce((acc, curr) =>
                    acc.completed_threats_count[0] / acc.threats_count[1] >
                    curr.completed_threats_count[0] / curr.threats_count[1]
                        ? curr
                        : acc
                );
                return LeftActiveGoalList;
            } else {
                const LeftActiveGoalList = filteredList?.reduce((acc, curr) =>
                    acc.completed_threats_count[0] / acc.threats_count[1] >
                    curr.completed_threats_count[0] / curr.threats_count[1]
                        ? curr
                        : acc
                );
                return LeftActiveGoalList;
            }
        }
    }

    const slideThreatLeft = () =>
        setThreatIndex((prev) => Math.max(0, prev - 1));

    const slideThreatRight = () =>
        setThreatIndex((prev) =>
            Math.min(skillList?.results.length - 1, prev + 1)
        );

    const threat = skillList?.results[threatIndex];

    const { materialsList, isLoadingMaterial } = useMaterialsSkill(threat?.id);
    const firstMaterial = materialsList?.results[0];

    const { leftSkillList } = useLeftSkillsGoal(leftActive?.id);

    const { weekProgress } = useUserWeekProgress();

    const sum =
        weekProgress?.reduce(
            (partialSum, week) => partialSum + week.opened_materials,
            0
        ) || 0;

    const handleLinkGoal = (id) => {
        if (leftActive !== undefined) {
            // console.log(active);
            navigate(`timeline/${id}`, { state: { leftActive } });
        }
    };

    const openActiveMaterial = useSessionPopup((state) => state.open);

    const handleClickLink = () => {
        if (materialsList?.results.length === 0) {
            setOpenPopupTest(true);
        } else {
            if (
                skillsToCompletionRight[threat?.id]?.isAvailable === true ||
                active?.is_ordered === false
            ) {
                if (typeof active.id !== "number") {
                    throw new Error("invalid activeId");
                }
                openActiveMaterial(null, threat?.id, null);
                // openActiveMaterial(active.id, null, firstMaterial.id);
                // navigate(`/session/${item.id}`);
            }
        }
    };

    const handleClickLinkActive = (materialId: number) => {
        if (typeof active.id !== "number") {
            throw new Error("invalid activeId");
        }
        openActiveMaterial(active.id, null, materialId);
    };

    const handleClickLinkThreat = (threatId: number, materialId: number) => {
        if (typeof threatId !== "number") {
            throw new Error("invalid threatId");
        }
        openActiveMaterial(null, threatId, materialId);
    };

    const skillsToCompletionLeft = transformSkills(
        leftSkillList?.results,
        leftActive
    );
    const skillsToCompletionRight = transformSkills(skillList?.results, active);

    let threatProgress = 0;
    if (threat?.quiz_result_score > 0) {
        threatProgress = threat?.quiz_result_score;
    } else if (threat?.completed_content_count > 0) {
        threatProgress =
            threat?.completed_content_count / threat?.content_count;
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth);
        });

        if (LeftActiveGoalList !== undefined) {
            setLeftActive(LeftActiveGoalList);
        }
        if (goalsList?.results !== undefined) {
            setLoadPage(false);
        }
        if (
            isLoadingGoal === true ||
            isLoadingSkill === true ||
            isLoadingMaterial === true ||
            goalsList?.results.length === 1
        ) {
            setLoadActiveInfo(true);
        } else {
            setLoadActiveInfo(false);
        }
    }, [
        width,
        LeftActiveGoalList,
        materialsList,
        isLoadingGoal,
        isLoadingSkill,
        isLoadingMaterial,
    ]);

    const materialToCompletion: Record<
        number,
        { isAvailable: boolean; prev: number | null }
    > = {};

    let isQuizAvailable = threat?.is_external_quiz;

    if (materialsList !== undefined) {
        for (const [i, material] of materialsList.results.entries()) {
            if (i === 0) {
                materialToCompletion[material.id] = {
                    isAvailable: true,
                    prev: null,
                };
            } else {
                const prevMat = materialsList.results[i - 1];
                const nextElem = { isAvailable: false, prev: prevMat.id };
                const prevAvailable =
                    materialToCompletion[prevMat.id].isAvailable;

                if (prevAvailable && prevMat.session.percent_progress >= 100) {
                    nextElem.isAvailable = true;
                }

                materialToCompletion[material.id] = nextElem;
            }

            if (
                i === materialsList.results.length - 1 &&
                materialToCompletion[material.id]?.isAvailable
            ) {
                isQuizAvailable =
                    isQuizAvailable || material.session.percent_progress >= 100;
            }
        }
    }
    if (
        (materialsList == undefined || materialsList?.count == 0) &&
        threat?.quiz
    ) {
        isQuizAvailable = true;
    }

    // TODO добавить крутилку
    if (isUserLoading || isUserError || isLoadingGoal)
        return <Loader text="Загрузка..." />;
    return (
        <section className="relative flex h-full w-full flex-col items-center overflow-y-scroll px-[5px] pb-[26px] max-[834px]:pb-[76px]">
            <div className="mt-[30px] flex w-full max-w-[1415px]">
                <div className="mr-[10px] flex w-full max-w-[1415px] flex-col gap-[65px] max-[834px]:gap-[60px]">
                    <div className="mb-[20px] flex flex-col gap-[40px]">
                        <h2 className="text-[20px] font-bold leading-[24px] text-[#264354] max-[834px]:text-[16px] max-[834px]:leading-[19.2px]">
                            Здравствуй, {user?.first_name}!
                        </h2>
                        <p className="text-tr-m font-semibold text-[#264354]">
                            Продолжайте достигать цели:
                        </p>
                    </div>
                </div>
            </div>
            {loadPage === false ? (
                <div className="flex w-full max-w-[1415px]">
                    <div
                        className="
                        gap-x-[25px] 
                        gap-y-[35px]
                        max-[800px]:flex
                        max-[800px]:w-full
                        max-[800px]:flex-col
                        max-[800px]:items-center
                        min-[800px]:grid 
                        min-[800px]:grid-cols-[minmax(335px,455px),minmax(420px,455px)] 
                        min-[800px]:grid-rows-3 
                        min-[1070px]:grid-cols-[minmax(420px,575px),minmax(330px,455px),minmax(250px,335px)] 
                        min-[1070px]:grid-rows-2 "
                    >
                        <div className="relative flex w-full ">
                            <div
                                className={` z-40 flex w-full flex-col  ${
                                    openList === true
                                        ? "absolute h-auto w-full"
                                        : " h-[665px]"
                                }  rounded-xl bg-[#3F4A50] px-[20px] py-[25px] `}
                            >
                                <div className="mt-[20px] flex w-full items-start justify-between">
                                    <div className="flex w-[375px] flex-col gap-y-[15px] text-tr-s font-medium text-white">
                                        <p className="text-tr-s font-medium text-white">
                                            Вы уже начали достигать цель
                                        </p>
                                        <p className="break-words text-tr-l font-bold text-white">
                                            {leftActive?.active.name}
                                        </p>
                                    </div>
                                    <ProgressCircle
                                        className="h-[144px] w-[144px]"
                                        colorLine="#FCA86F"
                                        colorText="white"
                                        ringType="big"
                                        progress={
                                            leftActive?.completed_threats_count /
                                            leftActive?.threats_count
                                        }
                                    />
                                </div>
                                <div
                                    className={`mt-[25px] flex h-full flex-col gap-y-[20px] ${
                                        openList === true
                                            ? " "
                                            : " max-h-[340px] overflow-hidden"
                                    } `}
                                >
                                    {leftSkillList?.results !== undefined && (
                                        <>
                                            {leftSkillList?.results.map(
                                                (skill) => {
                                                    return (
                                                        <LeftSkill
                                                            skill={skill}
                                                            activeOrdered={
                                                                leftActive.is_ordered
                                                            }
                                                            available={
                                                                skillsToCompletionLeft[
                                                                    skill.id
                                                                ]?.isAvailable
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </>
                                    )}
                                </div>
                                <div
                                    className={` mt-[20px] flex w-full items-center justify-between `}
                                >
                                    <>
                                        {leftSkillList?.results.length > 7 ? (
                                            <>
                                                {openList === false ? (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(true)
                                                        }
                                                    >
                                                        <p>
                                                            и еще{" "}
                                                            {leftSkillList
                                                                ?.results
                                                                .length -
                                                                7}{" "}
                                                            навыков
                                                        </p>
                                                        &#8595;
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="flex cursor-pointer gap-x-[5px] text-tr-s font-medium text-white"
                                                        onClick={() =>
                                                            setOpenList(false)
                                                        }
                                                    >
                                                        <p>Свернуть</p>
                                                        &#8593;
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                    </>
                                    <>
                                        {leftActive !== undefined && (
                                            <button
                                                className="block hover:-rotate-90"
                                                onClick={() =>
                                                    handleLinkGoal(
                                                        leftActive.id
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="55"
                                                    height="55"
                                                    viewBox="0 0 55 55"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="27.5"
                                                        cy="27.5"
                                                        r="27.5"
                                                        fill="white"
                                                    />
                                                    <path
                                                        d="M39.1354 34.2175L33.3619 24.2175L27.5884 34.2175L39.1354 34.2175ZM15.3652 21.6485L30.8412 30.5835L31.8412 28.8515L16.3652 19.9164L15.3652 21.6485Z"
                                                        fill="#3F4A50"
                                                    />
                                                </svg>
                                            </button>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>

                        <div className=" flex h-full max-h-[665px]  w-full flex-col gap-y-[20px] rounded-xl ">
                            <div className="flex h-full max-h-[190px] w-full flex-col rounded-xl px-[20px] pb-[10px] pt-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <div className="flex w-full justify-between">
                                    <p className="text-tr-s font-medium text-[#264354]">
                                        Средняя эффективность
                                    </p>
                                    {/* <div className="flex cursor-pointer items-center gap-x-[10px]">
                                        <p className="text-tr-s font-normal text-[#264354]">
                                            Неделя
                                        </p>
                                        <ListOpen className="mb-[2px]" />
                                    </div> */}
                                </div>

                                {sum > 0 ? (
                                    <WeekProgress weekData={weekProgress} />
                                ) : (
                                    <div className="flex h-full w-full flex-col items-center justify-between">
                                        <p className="mt-[55px] text-tr-s font-medium text-[#73C3F3]">
                                            Данных об эффективности нет
                                        </p>
                                        <div className="flex w-full items-end justify-between pl-[7px] pr-[8px]">
                                            {daysOfWeek.map((day, index) => (
                                                <div
                                                    key={index}
                                                    className="flex cursor-pointer items-center"
                                                >
                                                    <p className="text-tr-s font-medium text-[#264354]">
                                                        {daysOfWeek[index]}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex h-full max-h-[455px] w-full flex-col rounded-xl px-[25px] py-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-s font-semibold text-main-dark">
                                    Ваши достижения
                                </p>
                                <div className="w-fullrounded-xl flex h-full flex-col items-center justify-center gap-y-[15px]">
                                    <EmptyAchievements />
                                    <p className="text-tr-s font-semibold text-main-dark">
                                        Пока что тут пусто
                                    </p>
                                </div>
                                {/* <svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="28" cy="27.5" r="27.5" fill="#264354" fill-opacity="0.7"/>
                                    <path d="M38.8819 33.7822L33.1084 23.7822L27.3349 33.7822L38.8819 33.7822ZM15.8652 21.6483L30.5877 30.1483L31.5877 28.4162L16.8652 19.9162L15.8652 21.6483Z" fill="white"/>
                                </svg> */}
                            </div>
                        </div>

                        {loadActiveInfo === false ? (
                            <div className="flex h-full max-h-[665px] w-full flex-col items-center rounded-xl px-[20px] pb-[10px] pt-[20px] shadow-[0px_7px_22px_0px_#2643541A]">
                                <p className="text-tr-m font-medium text-main-dark">
                                    Ваш прогресс по целям
                                </p>
                                {goalsList?.results.length !== 0 ? (
                                    <>
                                        <div className="mt-[20px] flex w-full items-center justify-between">
                                            <div
                                                onClick={slideActiveLeft}
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[115px] w-[115px]"
                                                colorLine="#FCA86F"
                                                colorText="black"
                                                ringType="middle"
                                                progress={
                                                    active?.completed_threats_count /
                                                    active?.threats_count
                                                }
                                            />
                                            <div
                                                onClick={slideActiveRight}
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                            {active?.active.name}
                                        </p>

                                        <div className="mt-[30px] flex w-full items-center justify-between ">
                                            <div
                                                onClick={slideThreatLeft}
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListOpen className="rotate-90 " />
                                            </div>
                                            <ProgressCircle
                                                className="h-[80px] w-[80px]"
                                                colorLine="#369F48"
                                                colorText="black"
                                                ringType="short"
                                                progress={threatProgress}
                                                available={
                                                    skillsToCompletionRight[
                                                        threat?.id
                                                    ]?.isAvailable
                                                }
                                            />
                                            <div
                                                onClick={slideThreatRight}
                                                className="flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-full hover:bg-blue hover:bg-opacity-20"
                                            >
                                                <ListClose className="rotate-90 " />
                                            </div>
                                        </div>

                                        {active?.is_ordered === true ? (
                                            <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                                {skillsToCompletionRight[
                                                    threat?.id
                                                ]?.isAvailable === true ? (
                                                    <>{threat?.threat.name}</>
                                                ) : (
                                                    <>Недоступный навык</>
                                                )}
                                            </p>
                                        ) : (
                                            <p className="mt-[10px] text-tr-s font-medium text-main-dark">
                                                {threat?.threat.name}
                                            </p>
                                        )}

                                        <div className="mt-[20px] flex h-[280px] w-full flex-col gap-y-[10px] overflow-y-auto">
                                            {materialsList?.results?.length >
                                                0 || threat?.quiz !== null ? (
                                                <>
                                                    {materialsList?.results.map(
                                                        (material) => {
                                                            return (
                                                                <MaterialElem
                                                                    material={
                                                                        material
                                                                    }
                                                                    threat={
                                                                        threat
                                                                    }
                                                                    available={
                                                                        materialToCompletion[
                                                                            material
                                                                                .id
                                                                        ]
                                                                            .isAvailable
                                                                    }
                                                                    blocked={
                                                                        skillsToCompletionRight[
                                                                            threat
                                                                                ?.id
                                                                        ]
                                                                            ?.isAvailable
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                    {threat?.quiz !== null && (
                                                        <TestCard
                                                            editThreat={threat}
                                                            isQuizAvailable={
                                                                isQuizAvailable
                                                            }
                                                            setOpenPopupTest={
                                                                setOpenPopupTest
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <div className="flex h-full w-full flex-col items-center justify-center gap-y-[10px]">
                                                    <svg
                                                        width="43"
                                                        height="38"
                                                        viewBox="0 0 43 38"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3.76562 8.38492L17.0859 2.17639C19.9147 0.857869 23.0849 0.857871 25.9138 2.17639L39.2342 8.38501C42.1317 9.73551 42.1317 14.4104 39.2342 15.7609L25.914 21.9694C23.0851 23.288 19.9149 23.288 17.0861 21.9694L7.56475 17.5316"
                                                            stroke="#566DA3"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                        />
                                                        <path
                                                            d="M2.58789 24.9382V19.273C2.58789 16.7412 2.58789 15.4753 3.24547 14.4838C3.90304 13.4924 5.07339 12.9937 7.41409 11.9964L9.55548 11.084"
                                                            stroke="#566DA3"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M21.5016 36.8118C17.9262 36.8118 13.2474 34.5927 10.3242 32.9833C8.56875 32.0167 7.56641 30.1489 7.56641 28.1538V18.0098M35.4368 18.0098V28.1538C35.4368 30.1489 34.4344 32.0167 32.6789 32.9833C31.623 33.5646 30.3381 34.2255 28.9588 34.8327"
                                                            stroke="#566DA3"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                        />
                                                    </svg>
                                                    <p className="flex w-full max-w-[140px] text-center text-tr-xs font-medium text-[#566DA3]">
                                                        Скоро здесь будут
                                                        учебные материалы
                                                    </p>
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            onClick={handleClickLink}
                                            className="flex h-full max-h-[35px] w-full max-w-[295px] cursor-pointer items-center justify-center rounded-full bg-[#566DA3] text-white"
                                        >
                                            Изучить сразу
                                        </div>
                                    </>
                                ) : (
                                    <>Пока что нет добавленных целей</>
                                )}
                            </div>
                        ) : (
                            <ActiveInfoEmptySkeleton />
                        )}
                        <Calendar />

                        <Events />
                    </div>
                </div>
            ) : (
                <MainGoalSkeleton />
            )}
            {!openPopupTest ? (
                ""
            ) : (
                <PopupTest
                    session={null}
                    quiz={threat.quiz.id}
                    setOpenPopupTest={setOpenPopupTest}
                    typeQuiz="threat"
                />
            )}
        </section>
    );
}

export default MainPage;
