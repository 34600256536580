import { create } from "zustand";
import * as http from "shared/http";
import { Content } from "shared/types";

type State = {
    monthOffset: number;
    selectedDay: number;
};

type Action = {
    addMonthOffset: (inc: number) => void;
    setMonthOffset: (monthOffset: number) => void;
    setSelectedDay: (day: number) => void;
};

export const useStore = create<State & Action>((set) => ({
    monthOffset: 0,
    selectedDay: new Date().getDate(),
    addMonthOffset: (inc) => set((s) => ({ monthOffset: s.monthOffset + inc })),
    setMonthOffset: (monthOffset) => set({ monthOffset }),
    setSelectedDay: (day) => set({ selectedDay: day }),
}));

export function useStoreDate() {
    const monthOffset = useStore((s) => s.monthOffset);

    // смещаем месяц в дате
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + monthOffset);

    // выбираем год и месяц из смещенной даты
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return { currentMonth, currentYear };
}

export function getEvents(year: number | string, month: number | string) {
    return http.request<Content[]>({
        method: "get",
        url: `/api/v1/user/events?year=${year}&month=${month}`,
    });
}
