import * as http from "shared/http";
import * as types from "shared/types";
import useSWR from "swr";

export function getUser(url: string): Promise<types.User> {
    return http.request<types.User>({
        method: "get",
        url: url,
    });
}

export default function useUser() {
    const { data, error } = useSWR("/api/v1/profile", getUser);

    return {
        user: data,
        isLoading: !error && !data,
        isError: error,
    };
}
