import { ProgressBar } from "../ProgressBar/ProgressBar";
import Statistic from "shared/ui/icon/Statistic.svg";
import Img from "../../shared/ui/icon/RocketIcon.svg";
import { MaterialType } from "shared/types";
import { truncateText } from "../../utils/truncateText";
import { useNavigate } from "react-router-dom";

interface Props {
    material: MaterialType;
}

export const MaterialItemSmall: React.FC<Props> = ({ material }) => {
    const navigate = useNavigate();

    const handleClickLink = () => {
        navigate(`/session/${material.id}`);
        sessionStorage.clear();
    };

    return (
        <div
            className="group relative flex h-[410px] w-full cursor-pointer flex-col rounded-xl bg-white text-[16px] font-bold text-textblack shadow-[0_0_10px_0_rgba(38,67,84,0.1)]"
            onClick={handleClickLink}
        >
            <div className="mb-[20px] flex justify-between px-2.5 pt-2.5">
                <div className="flex flex-col gap-[5px]">
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#F37D73]">
                        {material?.content_type?.verbose_name}
                    </p>
                    <p className="text-[14px] font-semibold leading-[16.8px] text-textblack">
                        {truncateText(material?.name, 1.4)}
                    </p>
                </div>
            </div>
            <div className="relative flex h-full w-full flex-col overflow-hidden rounded-l-md px-2.5">
                <div className="mb-[21px] flex w-full justify-start text-end">
                    <div className="mb-[24px] flex min-w-[200px] justify-start text-end max-[1010px]:mb-[12px]">
                        <img
                            className="h-[22px] w-[22px]"
                            src={Statistic}
                            alt="statistic"
                        />
                        <p className="ml-[5px] mt-[5px] text-sm font-medium leading-[16.8px] text-[#264354B2] max-[1010px]:text-[10.78px] max-[1010px]:leading-[12.94px]">
                            Твой прогресс по учебному материалу
                        </p>
                    </div>
                </div>
                <div className="mb-[20px] flex w-full flex-col">
                    <p className="mb-[35px] text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Прогресс изучения материала
                    </p>
                    <ProgressBar
                        percent={material?.session?.percent_progress || 0}
                        background="#F37D73"
                        color="#F37D73"
                        height="6px"
                        fontSize="20px"
                        lineHeight="24px"
                        top="-30px"
                        right=""
                    />
                </div>
                <div className="relative flex w-full flex-col gap-4">
                    <p className="text-[12px] font-medium leading-[14.4px] text-[#264354B2]">
                        Достижения за прохождение
                    </p>
                    <p className="text-[14px] font-medium leading-[16.8px] text-[#73BDE9]">
                        Достижения за прохождение этого материала не добавлено
                    </p>
                </div>
                <img
                    className="absolute bottom-[-20px] right-[-20px] h-[136.66px] w-[112.06px] transform opacity-[0.7]"
                    src={Img}
                    alt="Картинка"
                />
            </div>
        </div>
    );
};
