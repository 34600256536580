import { create } from "zustand";

/**
 * Внешнее хранилище для состояния попапа. Позволяет
 * открывать/закрывать попап и переключать материалы снаружи попапа,
 * не передавая множество сеттеров наружу.
 */

interface ThreatSessionState {
    isOpen: boolean;
    activeId: number | null;
    threatId: number | null;
    materialId: number | null;
    setMaterialId: (materialId: number) => void;
    open: (activeId: number | null, threatId: number | null, materialId: number | null) => void;
    close: () => void;
}

export const useSessionPopup = create<ThreatSessionState>((set) => ({
    isOpen: false,
    activeId: null,
    threatId: null,
    materialId: null,
    close: () => set({ isOpen: false }),
    setMaterialId: (materialId) => set({ materialId }),
    open: (activeId, threatId, materialId = null) =>
        set({ isOpen: true, activeId, threatId, materialId }),
}));
