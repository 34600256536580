import React from "react";
import { ReactComponent as IconsButton } from "shared/ui/icon/IconsButtonTest.svg";

interface ButtonStartProps {
    text: string;
    handleClickLink: () => void;
}

export const ButtonStartTest: React.FC<ButtonStartProps> = ({
    text,
    handleClickLink,
}) => {
    return (
        <button 
            onClick={handleClickLink}
            className="z-[1] bg-[#FF7518] flex h-[40px] w-[235px] rounded-full shadow-[0_0_10px_0_rgba(38,67,84,0.1)] items-center justify-center
            text-tr-s font-medium text-main-dark"
        >
            <p className="mr-[15px] text-[14px] font-medium leading-[14px] text-white max-[1010px]:text-[8.92px] max-[1010px]:leading-[8.92px]">
                {text}
            </p>
            <IconsButton />
        </button>
    );
};
