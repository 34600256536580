import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ReactComponent as Logo } from "shared/image/icon/FullLogoIcon2.svg";
import { ReactComponent as EmailIcon } from "shared/image/icon/LoginInput.svg";
import { ReactComponent as PassIcon } from "shared/image/icon/PasswordInput.svg";
import studentPhoto from "shared/image/studentPhoto.png";
import Spinner from "shared/ui/Spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { authWithCredentials } from "shared/http";

type User = {
    email: string;
    first_name: string;
    surname: string | null;
    last_name: string | null;
    position: string | null;
    image: string | null;
};

function EnterEmailForm({ callback }: { callback: (email: string) => void }) {
    const [isLoading, setIsLoading] = useState(false);

    type FormInputs = { email: string };

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormInputs>();

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setIsLoading(true);
        try {
            await axios.post("/api/v1/user/reset_password/send_code", data);
            callback(data.email);
        } catch (err) {
            if (err?.response?.status === 404) {
                setError("email", {
                    type: "manual",
                    message: "Указанная почта не зарегистрирована",
                });
            } else {
                toast.error("Произошла ошибка при отправке кода");
            }

            console.error(err);
            setIsLoading(false);
        }
    };

    if (isLoading)
        return (
            <div className="mt-[100px]">
                <Spinner />
            </div>
        );

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[34px] flex w-[695px] flex-col text-main-dark/80"
        >
            <span className="text mx-auto text-tr-xxl font-bold">
                Обновите пароль
            </span>
            <label
                htmlFor="reset-password-email-form"
                className="mt-[80px] text-tr-l"
            >
                Адрес электронной почты
            </label>
            <div className="mt-[20px] flex border-b border-main-blue py-[10px]">
                <EmailIcon className="mx-[10px]" />
                <input
                    id="reset-password-email-form"
                    type="email"
                    placeholder="example@mail.ru"
                    {...register("email", { required: "Обязательное поле" })}
                    className="grow text-tr-s outline-none"
                />
            </div>
            {errors.email && <span>{errors.email.message}</span>}
            <button className="ml-auto mt-[40px] block rounded-full bg-main-blue px-[74.5px] py-[14px] text-tr-s font-medium text-monochrome-white hover:opacity-70">
                Продолжить
            </button>
        </form>
    );
}

function ConfirmEmailForm({
    email,
    callback,
}: {
    email: string;
    callback: (user: User, code: string) => void;
}) {
    const [isLoading, setIsLoading] = useState(false);

    type FormInputs = { code: string };

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormInputs>();

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setIsLoading(true);
        try {
            const { data: user } = await axios.get<User>(
                `/api/v1/user/reset_password/${data.code}/info`
            );
            callback(user, data.code);
        } catch (err) {
            if (err?.response?.status === 404) {
                setError("code", {
                    type: "manual",
                    message: "Проверьте правильность введенного кода",
                });
            } else if (err?.response?.data instanceof Array) {
                switch (err.response.data[0]) {
                    case "Token expired":
                        setError("code", {
                            type: "manual",
                            message: "Истек срок действия кода",
                        });
                        break;
                    case "Token has already been used":
                        setError("code", {
                            type: "manual",
                            message: "Истек срок действия кода",
                        });
                        break;
                    default:
                        setError("code", {
                            type: "manual",
                            message: "Неизвестная ошибка",
                        });
                        break;
                }
            } else {
                toast.error("Произошла ошибка при отправке кода");
            }
            console.error(err);
            setIsLoading(false);
        }
    };

    if (isLoading)
        return (
            <div className="mt-[100px]">
                <Spinner />
            </div>
        );

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[34px] flex w-[695px] flex-col text-main-dark/80"
        >
            <span className="text mx-auto text-tr-xxl font-bold">
                Обновите пароль
            </span>
            <label
                htmlFor="reset-password-confirm-form"
                className="mt-[80px] text-tr-l"
            >
                Введите одноразовый код который мы выслали на адрес
                <br />
                <span className="font-bold">{email}</span>
            </label>
            <div className="mt-[20px] flex border-b border-main-blue py-[10px]">
                <PassIcon className="mx-[10px]" />
                <input
                    id="reset-password-confirm-form"
                    type="password"
                    autoComplete="off"
                    placeholder="•••••••••"
                    {...register("code", { required: "Обязательное поле" })}
                    className="grow text-tr-s outline-none"
                />
            </div>
            {errors.code && <span>{errors.code.message}</span>}
            <button className="ml-auto mt-[40px] block rounded-full bg-main-blue px-[74.5px] py-[14px] text-tr-s font-medium text-monochrome-white hover:opacity-70">
                Продолжить
            </button>
        </form>
    );
}

function NewPasswordForm({
    user,
    code,
    reset,
}: {
    user: User;
    code: string;
    reset: () => void;
}) {
    const [isLoading, setIsLoading] = useState(false);

    type FormInputs = { new_password: string; confirm_password: string };

    const {
        watch,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormInputs>();

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        setIsLoading(true);
        try {
            await axios.post("/api/v1/user/reset_password/set_password", {
                code: code,
                new_password: data.new_password,
            });
            authWithCredentials({
                email: user.email,
                password: data.new_password,
            });
        } catch (err) {
            if (
                err?.response?.data instanceof Array &&
                err.response.data[0] === "Token expired"
            ) {
                toast.error("Истек срок действия кода");
                reset();
            } else if (err?.response?.data?.new_password instanceof Array) {
                const errors = {};
                for (const [i, e] of err.response.data.new_password.entries()) {
                    errors[`manual-${i}`] = e;
                }
                setError("new_password", { types: errors });
            } else {
                toast.error("Произошла ошибка при отправке кода");
            }
            console.error(err);
            setIsLoading(false);
        }
    };

    if (isLoading)
        return (
            <div className="mt-[100px]">
                <Spinner />
            </div>
        );

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[34px] flex w-[695px] flex-col text-main-dark/80"
        >
            <span className="text mx-auto text-tr-xxl font-bold ">
                Обновите пароль
            </span>

            <div className="mt-[40px] flex flex-col items-center">
                <img
                    src={user.image || studentPhoto}
                    alt="user avatar"
                    className="w-[104px]"
                />
                <span className="mt-[14px] text-tr-m font-medium ">
                    {user.first_name} {user.surname}
                </span>
                <span className="mt-[5px] text-tr-s">{user.email}</span>
                <span className="mt-[5px] text-tr-xs font-medium ">
                    {user.position}
                </span>
            </div>

            <label
                htmlFor="reset-password-newpassword-form-new"
                className="mt-[40px] text-tr-l"
            >
                Новый пароль
            </label>
            <div className="my-[20px] flex border-b border-main-blue py-[10px]">
                <PassIcon className="mx-[10px]" />
                <input
                    id="reset-password-newpassword-form-new"
                    type="password"
                    placeholder="•••••••••"
                    {...register("new_password", {
                        required: "Обязательное поле",
                    })}
                    className="grow text-tr-s outline-none"
                />
            </div>
            {errors?.new_password && <span>{errors.new_password.message}</span>}
            {errors?.new_password?.types &&
                Object.entries(errors.new_password.types).map(
                    ([key, error]) => <span key={key}>{error}</span>
                )}
            <label
                htmlFor="reset-password-newpassword-form-confirm"
                className="mt-[40px] text-tr-l"
            >
                Повторите пароль
            </label>
            <div className="my-[20px] flex border-b border-main-blue py-[10px]">
                <PassIcon className="mx-[10px]" />
                <input
                    id="reset-password-newpassword-form-confirm"
                    type="password"
                    placeholder="•••••••••"
                    className="grow text-tr-s outline-none"
                    {...register("confirm_password", {
                        required: "Обязательное поле",
                        validate: (v) =>
                            v === watch("new_password") ||
                            "Пароли должны совпадать",
                    })}
                />
            </div>
            {errors.confirm_password && (
                <span>{errors.confirm_password.message}</span>
            )}
            <button className="ml-auto mt-[40px] block rounded-full bg-main-blue px-[74.5px] py-[14px] text-tr-s font-medium text-monochrome-white hover:opacity-70">
                Обновить пароль
            </button>
        </form>
    );
}

type Step = "enter-email" | "confirm-email" | "new-password";

function ResetPasswordPage() {
    const [step, setStep] = useState<Step>("enter-email");

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [user, setUser] = useState<User | null>(null);

    let currentForm: React.ReactElement;
    switch (step) {
        case "enter-email":
            currentForm = (
                <EnterEmailForm
                    callback={(email) => {
                        setEmail(email);
                        setStep("confirm-email");
                    }}
                />
            );
            break;
        case "confirm-email":
            currentForm = (
                <ConfirmEmailForm
                    email={email}
                    callback={(user, code) => {
                        setUser(user);
                        setCode(code);
                        setStep("new-password");
                    }}
                />
            );
            break;
        case "new-password":
            currentForm = (
                <NewPasswordForm
                    user={user}
                    code={code}
                    reset={() => {
                        setStep("enter-email");
                        setEmail("");
                        setCode("");
                        setUser(null);
                    }}
                />
            );
            break;
    }

    return (
        <section className="flex flex-col items-center">
            <Logo className="mt-[134px] w-[386px]" />
            {currentForm}
        </section>
    );
}

export default ResetPasswordPage;
