

export const ArrowComponent = () => {
    return (
        <div className="flex min-h-[50px] h-full grow">
            <div className="flex h-full flex-col items-center pb-[5px]">
                {/* <div className="flex h-[10px] w-[10px] items-center justify-center rounded-full border bg-[#F37D7380] text-[white]"></div> */}
                <div className="h-full w-[1px] border-dashed border-[1px] opacity-50 border-[#566DA3]"></div>
                <svg className="mt-[-45px]" width="8" height="48" viewBox="0 0 8 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.64645 47.3536C3.84171 47.5488 4.15829 47.5488 4.35356 47.3536L7.53554 44.1716C7.7308 43.9763 7.7308 43.6597 7.53554 43.4645C7.34027 43.2692 7.02369 43.2692 6.82843 43.4645L4 46.2929L1.17157 43.4645C0.976313 43.2692 0.65973 43.2692 0.464468 43.4645C0.269206 43.6597 0.269206 43.9763 0.464468 44.1716L3.64645 47.3536ZM3.5 2.18557e-08L3.5" fill="#566DA3"/>
                </svg>
            </div>
        </div>
    );
};
